import dayjs from 'dayjs';
import { createContext, useContext, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
// import { useFetchChatRoomById } from 'src/modules/chat/hooks/queries/useFetchChatRoom';
import { useFetchNotifications } from 'src/modules/notifications/hooks/queries/useFetchNotifications';
import { NewsOptions } from 'src/services/api/options/newsOptions';
import { AttendenceDTO } from 'src/services/api/models/attendanceDto';
// import { useInterval } from 'src/services/helpers/useInterval';
// import { EmployeeAPI } from 'src/services/api/employeeApi';
import { useSelector } from 'react-redux';
import { RootStateType } from 'src/services/redux/reducers';
import { NotificationOptions } from '../api/options/notificationOptions';
import { ChatRoomResponse } from '../api/response/chatResponse';
import { EmployeeResponse } from '../api/response/employeeResponse';
import { NotificationResponse } from '../api/response/notificationResponse';
import ConsoleHelper from '../helpers/consoleHelper';

export interface Globals {
  selectedDate: Date;
  setSelectedDate: (date: Date) => void;
  selectedEndDate: Date | undefined;
  setSelectedEndDate: (date?: Date) => void;
  showChatModal: boolean;
  setShowChatModal: (isOpen: boolean) => void;
  currentChatRoom: ChatRoomResponse | undefined;
  unreadChats: number | undefined;
  setUnreadChats: (unread: number) => void;
  setCurrentChatRoom: (chatRoomResponse: ChatRoomResponse | undefined) => void;
  loggedInUser: EmployeeResponse | undefined;
  setLoggedInUser: (user: EmployeeResponse) => void;
  checkInStatusUser: AttendenceDTO | undefined;
  setCheckInStatusUser: (user: AttendenceDTO) => void;
  notifications: NotificationResponse[] | undefined;
  unreadNotifications: NotificationResponse[] | undefined;
  language: string;
  setLanguage: (lng: string) => void;
  newsOptions: NewsOptions | undefined;
  setNewsOptions: (options: NewsOptions) => void;
}

export const GlobalContext = createContext<Globals>({
  selectedDate: new Date(),
  setSelectedDate: () => undefined,
  selectedEndDate: undefined,
  setSelectedEndDate: () => undefined,
  showChatModal: false,
  setShowChatModal: () => undefined,
  currentChatRoom: undefined,
  setCurrentChatRoom: () => undefined,
  unreadChats: 0,
  setUnreadChats: () => undefined,
  loggedInUser: undefined,
  setLoggedInUser: () => undefined,
  checkInStatusUser: undefined,
  setCheckInStatusUser: () => undefined,
  notifications: undefined,
  unreadNotifications: undefined,
  language: 'sv',
  setLanguage: () => undefined,
  newsOptions: undefined,
  setNewsOptions: () => undefined,
});

export const useGlobals = () => {
  const context = useContext(GlobalContext);
  if (context === undefined) {
    throw new Error('useGlobals must be within AppContext');
  }
  return context;
};

interface Props {
  children: JSX.Element;
}

export const AppContext = ({ children }: Props) => {
  const { i18n } = useTranslation();
  const { user } = useSelector((state: RootStateType) => state.oidc);
  const [loggedInUser, setLoggedInUser] = useState<EmployeeResponse | undefined>();
  const [checkInStatusUser, setCheckInStatusUser] = useState<AttendenceDTO | undefined>();
  const [selectedDate, setSelectedDate] = useState<Date>(new Date());
  const [selectedEndDate, setSelectedEndDate] = useState<Date | undefined>(undefined);
  const [showChatModal, setShowChatModal] = useState<boolean>(false);
  const [currentChatRoom, setCurrentChatRoom] = useState<ChatRoomResponse>();
  const [unreadChats, setUnreadChats] = useState<number | undefined>();
  const [notifications, setNotifications] = useState<NotificationResponse[]>([]);
  const [unreadNotifications, setUnreadNotifications] = useState<NotificationResponse[]>([]);
  const [language, setLanguage] = useState('sv');
  const [newsOptions, setNewsOptions] = useState<NewsOptions>();

  // ---------------------------------

  const options = new NotificationOptions();
  const { data, isLoading, isError } = useFetchNotifications(options, loggedInUser);

  useEffect(() => {
    // ConsoleHelper.log('setNotifications in AppContext', data);
    setNotifications(data ?? []);
    const filteredUnread = data?.filter((item) => !item.is_read);
    setUnreadNotifications(filteredUnread ?? []);
  }, [data]);

  // const fetchUserMe = async () => {
  //   const employeeApi = new EmployeeAPI();
  //   const result = await employeeApi.GetLoggedInUser();
  //   const checkInUser: AttendenceDTO = {
  //     userGuid: result.GUID,
  //     userImage: result.Image,
  //     firstName: result.Firstname,
  //     currentAttendence: result.CurrentAttendance,
  //     currentAbsence: result.CurrentAbsence,
  //     onDuty: result.OnDuty,
  //   };
  //   setCheckInStatusUser(checkInUser);
  // };

  useEffect(() => {
    const initialLng = window.localStorage.getItem('language');
    if (initialLng) {
      setLanguage(initialLng);
    }
  }, []);

  useEffect(() => {
    if (language === 'sv' || language === 'helsinge') dayjs.locale('sv');
    else dayjs.locale('en');
    window.localStorage.setItem('language', language);
    i18n.changeLanguage(language);
  }, [i18n, language]);

  const values = useMemo(
    () => ({
      selectedDate,
      setSelectedDate,
      selectedEndDate,
      setSelectedEndDate,
      showChatModal,
      setShowChatModal,
      currentChatRoom,
      setCurrentChatRoom,
      unreadChats,
      setUnreadChats,
      loggedInUser,
      setLoggedInUser,
      checkInStatusUser,
      setCheckInStatusUser,
      notifications,
      unreadNotifications,
      language,
      setLanguage,
      newsOptions,
      setNewsOptions,
    }),
    [
      selectedDate,
      selectedEndDate,
      showChatModal,
      currentChatRoom,
      unreadChats,
      loggedInUser,
      checkInStatusUser,
      notifications,
      unreadNotifications,
      language,
      newsOptions,
    ],
  );

  return <GlobalContext.Provider value={values}>{children}</GlobalContext.Provider>;
};
