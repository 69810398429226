import { AxiosInstance } from 'src/services/axios/interceptors';
import { BaseAPI } from 'src/services/api/baseApi';
import { DepartmentOptions } from 'src/services/api/options/departmentOptions';
import { DepartmentRequest } from 'src/services/api/request/departmentRequest';
import { DepartmentResponse } from 'src/services/api/response/departmentResponse';

export class DepartmentAPI extends BaseAPI<DepartmentResponse, DepartmentOptions, DepartmentRequest> {
  constructor() {
    super('Department');
  }

  public GetDepartments = () => AxiosInstance.get(`${this.controllerPath}`);
}
