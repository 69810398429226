// Paket i18n
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
// Svenska
import translationSECommon from 'src/shared/locales/sv/common.json';
import translationSENews from 'src/shared/locales/sv/news.json';
import translationSEEmployees from 'src/shared/locales/sv/employees.json';
import translationSEProgress from 'src/shared/locales/sv/progress.json';
import translationSECalendar from 'src/shared/locales/sv/calendar.json';
import translationSECareer from 'src/shared/locales/sv/career.json';
import translationSEChat from 'src/shared/locales/sv/chat.json';
import translationSELunch from 'src/shared/locales/sv/lunch.json';
import translationSEForm from 'src/shared/locales/sv/form.json';
import translationSEBooking from 'src/shared/locales/sv/booking.json';

// Engelska
import translationENCommon from 'src/shared/locales/en/common.json';
import translationENNews from 'src/shared/locales/en/news.json';
import translationENEmployees from 'src/shared/locales/en/employees.json';
import translationENProgress from 'src/shared/locales/en/progress.json';
import translationENCalendar from 'src/shared/locales/en/calendar.json';
import translationENCareer from 'src/shared/locales/en/career.json';
import translationENChat from 'src/shared/locales/en/chat.json';
import translationENLunch from 'src/shared/locales/en/lunch.json';
import translationENForm from 'src/shared/locales/en/form.json';
import translationENBooking from 'src/shared/locales/en/booking.json';

// Hälsingemål
import translationSEHelsingeCommon from 'src/shared/locales/sv-helsinge/common.json';
import translationSEHelsingeNews from 'src/shared/locales/sv-helsinge/news.json';
import translationSEHelsingeEmployees from 'src/shared/locales/sv-helsinge/employees.json';
import translationSEHelsingeProgress from 'src/shared/locales/sv-helsinge/progress.json';
import translationSEHelsingeCalendar from 'src/shared/locales/sv-helsinge/calendar.json';
import translationSEHelsingeCarer from 'src/shared/locales/sv-helsinge/career.json';
import translationSEHelsingeChat from 'src/shared/locales/sv-helsinge/chat.json';
import translationSEHelsingeLunch from 'src/shared/locales/sv-helsinge/lunch.json';
import translationSEHelsingeForm from 'src/shared/locales/sv-helsinge/form.json';
import translationSEHelsingeBooking from 'src/shared/locales/sv-helsinge/booking.json';
import { getLanguageFromStorage } from './shared/locales/getLanguageFromStorage';

const resources = {
  en: {
    common: translationENCommon,
    news: translationENNews,
    employees: translationENEmployees,
    progress: translationENProgress,
    calendar: translationENCalendar,
    career: translationENCareer,
    chat: translationENChat,
    lunch: translationENLunch,
    form: translationENForm,
    booking: translationENBooking,
  },
  sv: {
    common: translationSECommon,
    news: translationSENews,
    employees: translationSEEmployees,
    progress: translationSEProgress,
    calendar: translationSECalendar,
    career: translationSECareer,
    chat: translationSEChat,
    lunch: translationSELunch,
    form: translationSEForm,
    booking: translationSEBooking,
  },
  helsinge: {
    common: translationSEHelsingeCommon,
    news: translationSEHelsingeNews,
    employees: translationSEHelsingeEmployees,
    progress: translationSEHelsingeProgress,
    calendar: translationSEHelsingeCalendar,
    career: translationSEHelsingeCarer,
    chat: translationSEHelsingeChat,
    lunch: translationSEHelsingeLunch,
    form: translationSEHelsingeForm,
    booking: translationSEHelsingeBooking,
  },
};

i18n.use(initReactI18next).init({
  resources,
  lng: getLanguageFromStorage(),
  supportedLngs: ['sv', 'en', 'helsinge'],
  ns: ['common', 'news', 'employees', 'progress', 'calendar', 'career', 'chat', 'lunch', 'form', 'booking'],
  defaultNS: 'common',
  interpolation: {
    escapeValue: false,
  },
});

export default i18n;
