import { useInfiniteQuery, useQuery } from '@tanstack/react-query';
import { chatKeys } from 'src/modules/chat/hooks/chatKeys';
import { ChatRoomAPI } from 'src/services/api/chatRoomApi';
import { InfinityList } from 'src/services/api/helpers/infinityList';
import { ChatRoomOptions } from 'src/services/api/options/chatOptions';
import { ChatRoomResponse } from 'src/services/api/response/chatResponse';
// import ConsoleHelper from 'src/services/helpers/consoleHelper';

const chatroomApi = new ChatRoomAPI();
const PAGE_SIZE = 20;

export const getChatrooms = async (chatroomOptions: ChatRoomOptions): Promise<InfinityList<ChatRoomResponse>> => {
  const res = await chatroomApi.GetAll(chatroomOptions);
  // ConsoleHelper.log('res getChatrooms::', res);
  return {
    result: res.Results,
    nextPage: res.CurrentPage + 1,
    totalPages: res.TotalPages,
  };
};

export const getPinnedChatrooms = async (chatroomOptions: ChatRoomOptions) => {
  const res = await chatroomApi.GetAll(chatroomOptions);
  // ConsoleHelper.log('pinnedData::', res);
  return res.PinnedResults;
};

export const useFetchInfinityChatrooms = (chatroomOptions: ChatRoomOptions) => {
  const { PageSize = PAGE_SIZE } = chatroomOptions;
  return useInfiniteQuery({
    queryKey: chatKeys.infinityList({ ...chatroomOptions, PageSize }),
    queryFn: ({ pageParam = 0 }) =>
      getChatrooms({
        ...chatroomOptions,
        PageIndex: pageParam,
        PageSize,
      }),
    getNextPageParam: (lastPage: InfinityList<ChatRoomResponse>) => {
      if (lastPage.result.length === PAGE_SIZE) return lastPage.nextPage;
      return undefined;
    },
    keepPreviousData: true,
    staleTime: 0,
    refetchInterval: () => 30 * 1000,
  });
};

export const useFetchPinnedChatrooms = (chatroomOptions: ChatRoomOptions) => {
  return useQuery({
    queryKey: chatKeys.pinnedList(chatroomOptions),
    queryFn: () => getPinnedChatrooms(chatroomOptions),
  });
};

export {};
