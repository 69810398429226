import { AppDispatchTypes, SET_DOMAIN } from '../actions/appActions';

interface IAppState {
  domain: string;
}

const initState: IAppState = {
  domain: '',
};

export const appReducer = (state = initState, action: AppDispatchTypes): IAppState => {
  switch (action.type) {
    case SET_DOMAIN: {
      return {
        ...state,
        domain: action.payload,
      };
    }
    default: {
      return state;
    }
  }
};
