import { ErrorMessage } from '@hookform/error-message';
import { FieldErrors, FieldValues } from 'react-hook-form';
import { Alert, AlertTitle, Box, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

type FormErrorSummaryProps<T extends FieldValues> = {
  translationNamespace: string;
  errors: FieldErrors<T>;
};
export function FormErrorSummary<T extends FieldValues>({ errors, translationNamespace }: FormErrorSummaryProps<T>) {
  const { t } = useTranslation(['common', translationNamespace]);
  if (Object.keys(errors).length === 0) {
    return null;
  }
  return (
    <Alert
      severity='warning'
      role='alert'
      sx={{
        borderWidth: 2,
        borderStyle: 'solid',
        borderColor: 'warning.dark',
        mt: 3,
      }}
    >
      <Box>
        <AlertTitle>{t('common:errorSummary.header')}</AlertTitle>
        <Typography variant='body2' component='div'>
          {t('common:errorSummary.info')}
        </Typography>
      </Box>
      <ul style={{ padding: 0 }}>
        {Object.keys(errors).map((fieldName) => (
          <li key={fieldName}>
            <strong>{t(`${translationNamespace}:formFields.${fieldName}`)}</strong>
            {' — '}
            <ErrorMessage errors={errors} name={fieldName as any} as='span' key={fieldName} />
          </li>
        ))}
      </ul>
    </Alert>
  );
}
