import { useNavigate } from 'react-router-dom';
import { CallbackComponent } from 'redux-oidc';
import userManager from 'src/services/identity/userManager';
import { RoutesEnum } from 'src/shared/enums/routes.enum';
import ConsoleHelper from 'src/services/helpers/consoleHelper';
import { Box, Typography } from '@mui/material';

const CallbackPage = () => {
  const navigate = useNavigate();
  return (
    <CallbackComponent
      userManager={userManager}
      successCallback={() => {
        navigate(RoutesEnum.Start);
      }}
      errorCallback={(error) => {
        navigate(RoutesEnum.Start);
        ConsoleHelper.error(error);
      }}
    >
      <Box
        sx={{
          width: '100vw',
          height: '100vh',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Typography variant='h6' sx={{ color: 'text.primary' }}>
          Redirecting...
        </Typography>
      </Box>
    </CallbackComponent>
  );
};

export default CallbackPage;
