import React, { Suspense } from 'react';
import { useSelector } from 'react-redux';
import { Navigate, useLocation } from 'react-router-dom';
import { RootStateType } from 'src/services/redux/reducers';
import { RoutesEnum } from 'src/shared/enums/routes.enum';

const RequireAuth = ({ children }: { children: JSX.Element }) => {
  const user = useSelector((state: RootStateType) => state.oidc.user);
  const location = useLocation();

  if (user && user?.access_token) {
    // Redirect them to the /login page, but save the current location they were
    // trying to go to when they were redirected. This allows us to send them
    // along to that page after they login, which is a nicer user experience
    // than dropping them off on the home page.
    return <Suspense fallback={<div />}>{children}</Suspense>;
  }
  return <Navigate to={RoutesEnum.Login} state={{ from: location }} replace />;
};

export default RequireAuth;
