import { AxiosInstance } from 'src/services/axios/interceptors';
import { PagedResult } from 'src/services/api/helpers/pagedResult';
import { NewsRequest } from './request/newsRequest';

export abstract class BaseAPI<IResponse, IOptions, IRequest> {
  protected readonly controllerPath: string;

  constructor(controllerPath: string) {
    this.controllerPath = controllerPath;
  }

  public Get = (id: number | string) => AxiosInstance.get<IResponse>(`${this.controllerPath}/${id}`, {});

  public GetAll = (options: IOptions) =>
    AxiosInstance.get<PagedResult<IResponse>>(`${this.controllerPath}`, {
      params: options,
    });

  public Create = (req: IRequest) => AxiosInstance.post<IResponse>(`${this.controllerPath}`, req);

  public Update = (req: IRequest) => AxiosInstance.put<IResponse>(`${this.controllerPath}`, req);

  public Delete = (id: number) => AxiosInstance.delete<IResponse>(`${this.controllerPath}/${id}`);

  public CreateList = (req: Array<IRequest>) => AxiosInstance.post<IResponse>(`${this.controllerPath}/list`, req);
}
