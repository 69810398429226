import * as yup from 'yup';

export const NewsSchema = (t: (n: string) => void) =>
  yup.object().shape({
    ID: yup.number().optional(),
    Heading: yup
      .string()
      .required(`${t('news:isRequired')}`)
      .min(5),
    CategoryId: yup.number().required(`${t('news:isRequired')}`),
    PublishedDate: yup.date().required(`${t('news:isRequired')}`),
    DeadlineDate: yup.date().optional().min(yup.ref('PublishedDate'), 'Deadline date must be after Published date.'),
    ImageMediaId: yup.number().optional(),
    Teaser: yup.string().optional(),
    BodyText: yup.string().required(`${t('news:isRequired')}`),
    PDF: yup.string().optional(),
    PDFs: yup.array().nullable().of(yup.string()).optional(),
    Departments: yup.array().of(yup.mixed()).optional(),
    CanInvite: yup.boolean().required(),
    SendPush: yup.boolean().required(),
    CanBeCommented: yup.boolean().required(),
    DocumentMediaIds: yup.array().of(yup.number()).optional(),
    DepartmentIds: yup.array().of(yup.number()).optional(),
    ShowConfirmAction: yup.boolean().required(),
  });
