import { useMutation, useQueryClient } from '@tanstack/react-query';
import { newsKeys } from 'src/modules/home/hooks/newsKeys';
import { NewsAPI } from 'src/services/api/newsApi';
import { NewsRequest } from 'src/services/api/request/newsRequest';

const newsApi = new NewsAPI();
const unPublishNews = async (newsItemId: string) => {
  const res = await newsApi.Unpublish(newsItemId);
  return res;
};
export const useUnPublishNews = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: unPublishNews,
    onSuccess: () => {
      queryClient.invalidateQueries(newsKeys.all);
    },
  });
};
const createNews = async (newNews: NewsRequest) => {
  const res = await newsApi.Create(newNews);
  return res;
};

const editNews = async (editedNews: NewsRequest) => {
  const res = await newsApi.Update(editedNews);
  return res;
};

export const useCreateNews = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: createNews,
    onSuccess: () => {
      queryClient.invalidateQueries(newsKeys.all);
    },
  });
};

export const useEditNews = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: editNews,

    onSuccess: () => {
      queryClient.invalidateQueries(newsKeys.all);
    },
  });
};
