import { Controller, useFormContext, useFormState } from 'react-hook-form';
import { Select, MenuItem, FormHelperText, useMediaQuery, Typography, Box } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { NewsCategory } from 'src/services/api/response/newsCategory';
import { useTranslation } from 'react-i18next';

type ControlledSelectCategoryProps = {
  name: string;
  label?: string;
  selectItems: NewsCategory[];
  info?: string;
};

const ControlledSelectCategory = (props: ControlledSelectCategoryProps) => {
  const { name, label, selectItems, info } = props;
  const { t } = useTranslation(['common', 'news']);
  const { control } = useFormContext();
  const { errors } = useFormState();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  let isError = false;
  let errorMessage: string | any = '';
  if (errors && Object.prototype.hasOwnProperty.call(errors, name)) {
    isError = true;
    errorMessage = errors[name]?.message;
  }

  return (
    <>
      {label && (
        <Box sx={{ display: 'flex', mt: 1.5, mb: 0.5 }}>
          <Typography variant='subtitle2'>{label}</Typography>
        </Box>
      )}
      <Controller
        control={control}
        name={name}
        render={({ field }) => (
          <>
            <Select {...field} onChange={(item) => field.onChange(item)} value={field.value} size={isMobile ? 'small' : 'medium'} fullWidth>
              {selectItems.map((item) => (
                <MenuItem key={item.Id} value={item.Id}>
                  {t(`dropdown.${item.Name}`)}
                </MenuItem>
              ))}
            </Select>
            <Typography sx={{ color: theme.palette.grey[500], fontStyle: 'italic' }} variant='subtitle1'>
              {info}
            </Typography>
            <FormHelperText sx={{ color: 'error.main' }}>{isError ? errorMessage : ''}</FormHelperText>
          </>
        )}
      />
    </>
  );
};
ControlledSelectCategory.defaultProps = {
  label: null,
  info: undefined,
};
export default ControlledSelectCategory;
