// COMPONENTS
// import { ScrollToTop } from 'src/shared/components/atoms/scrollToTop';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Outlet } from 'react-router-dom';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import TopNavigation from '../../components/topNavigation';

const AppRoutesLayout = () => {
  return (
    <>
      <ToastContainer position='bottom-right' autoClose={5000} hideProgressBar={false} closeOnClick pauseOnHover draggable theme='colored' />
      {/* <ScrollToTop />  */}
      {/* Header Navigation */}
      <TopNavigation />

      {/* Main content - module pages */}
      <Outlet />
      <ReactQueryDevtools initialIsOpen />
    </>
  );
};

export default AppRoutesLayout;
