import { Suspense } from 'react';
import { useSelector } from 'react-redux';
import { Navigate, useLocation, useNavigate } from 'react-router-dom';
import { useGlobals } from 'src/services/contexts/appContext';
import { RootStateType } from 'src/services/redux/reducers';
import { RoutesEnum } from 'src/shared/enums/routes.enum';
import { UserTypeEnum } from '../../enums/userType.enum';

export function RequireAdminStatus({ children }: { children: JSX.Element }) {
  const { loggedInUser } = useGlobals();
  const user = useSelector((state: RootStateType) => state.oidc.user);
  const location = useLocation();
  if (!user?.access_token && !user) {
    return <Navigate to={RoutesEnum.Login} state={{ from: location }} replace />;
  }
  if (loggedInUser?.UserType !== UserTypeEnum.Admin) {
    return <Navigate to='/' state={{ from: location }} replace />;
  }

  return <Suspense fallback={<div />}>{children}</Suspense>;
}
