import React from 'react';
import 'src/index.css';
import 'src/i18n';
import App from 'src/App';
import * as serviceWorkerRegistration from 'src/serviceWorkerRegistration';
import reportWebVitals from 'src/reportWebVitals';
import { QueryClientProvider, QueryClient } from '@tanstack/react-query';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { store, persistor } from 'src/services/redux/store';
import { OidcProvider } from 'redux-oidc';
import userManager from 'src/services/identity/userManager';
import { AppContext } from 'src/services/contexts/appContext';
import ReactDOM from 'react-dom';
import 'dayjs/locale/sv';
import 'dayjs/locale/en';

// React Query client
const client = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: 10 * 1000,
    },
  },
});

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <OidcProvider store={store} userManager={userManager}>
        <PersistGate loading={null} persistor={persistor}>
          <QueryClientProvider client={client}>
            <AppContext>
              <App />
            </AppContext>
          </QueryClientProvider>
        </PersistGate>
      </OidcProvider>
    </Provider>
  </React.StrictMode>,
  document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
