/* eslint-disable max-classes-per-file */
export class ChatRoomOptions {
  IncludeDeleted?: boolean;

  Filter?: string;

  PageIndex?: number;

  PageSize?: number;

  SortDir?: string;

  SortCol?: string;

  From?: string | Date;

  UserId?: string;

  constructor() {
    this.IncludeDeleted = false;
  }
}

export class ChatRoomEmployeeOptions {
  IncludeDeleted?: boolean;

  ChatRoomID?: number;

  Filter?: string;

  PageIndex?: number;

  PageSize?: number;

  SortDir?: string;

  SortCol?: string;

  constructor() {
    this.IncludeDeleted = false;
  }
}
export class ChatRoomMessageOptions {
  IncludeDeleted?: boolean;

  ChatRoomID?: number;

  Filter?: string;

  PageIndex?: number;

  PageSize?: number;

  SortDir?: string;

  SortCol?: string;

  From?: string | Date;

  constructor() {
    this.IncludeDeleted = true;
  }
}
