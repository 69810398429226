import React, { useState } from 'react';
import { Card, CardHeader, CardContent, Typography, IconButton, Avatar, Box } from '@mui/material';
import { Delete } from '@mui/icons-material';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import { useGlobals } from 'src/services/contexts/appContext';
import { Colors } from 'src/shared/components/styles/colors';
import { NewsResponse } from 'src/services/api/response/newsResponse';
import { toast } from 'react-toastify';
import ConfirmDialogModal from 'src/shared/components/atoms/confirmDialog';
import { useTranslation } from 'react-i18next';
import { useDeleteComment } from 'src/modules/home/hooks/mutations/useComments';
import { useFetchNewsById } from 'src/modules/home/hooks/queries/useFetchNewsById';

type CommentsListProps = {
  items: NewsResponse;
};

const CommentsList = (props: CommentsListProps) => {
  const { items } = props;
  const { t } = useTranslation();
  dayjs.extend(relativeTime);

  const { loggedInUser } = useGlobals();
  const [openDialog, setOpenDialog] = React.useState(false);
  const [commentToDelete, setCommentToDelete] = useState<string | undefined>(undefined);
  const getNews = useFetchNewsById(items.GUID);
  const deleteComment = useDeleteComment();

  const handleClickOpen = () => {
    setOpenDialog(true);
  };

  const handleClose = () => {
    setOpenDialog(false);
    setCommentToDelete(undefined);
  };

  const deleteCommentHandler = async () => {
    if (commentToDelete) {
      deleteComment.mutate(
        { guid: items.GUID, comment: commentToDelete },
        {
          onSuccess: () => {
            handleClose();
            toast.success(t('news:commentIsDeleted'));
          },
        },
      );
    }
  };

  return (
    <Box id='commentSection'>
      {items ? (
        items.Comments.slice()
          .reverse()
          .map((comment) => {
            return (
              <Card sx={{ backgroundColor: Colors.BACKGROUND, mb: 2 }} key={comment.ID} raised>
                {comment && (
                  <CardHeader
                    title={comment.Name ?? 'Namn saknas'}
                    avatar={<Avatar>{comment.Name.charAt(0) ?? ''}</Avatar>}
                    subheader={<Box sx={{ color: Colors.GRAY_DARK }}>{dayjs(comment.Date).fromNow()}</Box>}
                    action={
                      comment.UserID === loggedInUser?.GUID ? (
                        <>
                          <IconButton
                            onClick={() => {
                              handleClickOpen();
                              setCommentToDelete(comment.ID);
                            }}
                          >
                            <Delete sx={{ color: Colors.ACCENT_DARK }} />
                          </IconButton>
                          <ConfirmDialogModal
                            open={openDialog}
                            dialogTitle={t('common:confirm')}
                            dialogText={t('news:confirmDeleteComment')}
                            handleClose={() => handleClose()}
                            confirmButtonClick={() => deleteCommentHandler()}
                            confirmTextButton={t('common:yesDelete')}
                            cancelTextButton={t('common:cancel')}
                          />
                        </>
                      ) : null
                    }
                  />
                )}
                <CardContent>
                  <Typography variant='body2' component='div'>
                    {comment.Content}
                  </Typography>
                </CardContent>
              </Card>
            );
          })
      ) : (
        <></>
      )}
    </Box>
  );
};

export default CommentsList;
