import { BaseAPI } from 'src/services/api/baseApi';
import { AxiosInstance } from 'src/services/axios/interceptors';
import { AbsenceOptions } from './options/absenceOptions';
import { AbsenceManagerCommentRequest, AbsenceRequest } from './request/absenceRequest';
import { AbsenceResponse } from './response/absenceResponse';

export class AbsenceAPI extends BaseAPI<AbsenceResponse, AbsenceOptions, AbsenceRequest> {
  constructor() {
    super('Absence');
  }

  public GetAbsenceById = (guid: string) => AxiosInstance.get(`${this.controllerPath}/${guid}`);

  public RemoveOwnAbsence = (guid: string) => AxiosInstance.delete(`${this.controllerPath}?guid=${guid}`);

  public ApproveAbsence = (guid: string) => AxiosInstance.post(`${this.controllerPath}/${guid}/approve`);

  public DisapproveAbsence = (guid: string, req: AbsenceManagerCommentRequest | undefined) =>
    AxiosInstance.post(`${this.controllerPath}/${guid}/disapprove`, req);
  // public DisapproveAbsenceWithComment = (guid: string, req: any) => AxiosInstance.post(`${this.controllerPath}/${guid}/disapprove`, req);

  public GenerateAbsenceReport = (from: string, to: string) => AxiosInstance.get(`${this.controllerPath}/report?From=${from}&To=${to}`);

  public FetchUnAnsweredAbsence = (from: string, to: string) => AxiosInstance.get(`${this.controllerPath}/unansweredAbsence?From=${from}&To=${to}`);
}
