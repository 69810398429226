import React from 'react';
import { NewsAPI } from 'src/services/api/newsApi';
import { useMutation, useQueryClient, InfiniteData } from '@tanstack/react-query';
import { NewsResponse } from 'src/services/api/response/newsResponse';
import { newsKeys } from 'src/modules/home/hooks/newsKeys';
import { useGlobals } from 'src/services/contexts/appContext';
import { NewsOptions } from 'src/services/api/options/newsOptions';
import { InfinityList } from 'src/services/api/helpers/infinityList';

const likeNews = async (guid: string) => {
  const newsApi = new NewsAPI();
  await newsApi.LikeNews(guid);
};

const dislikeNews = async (guid: string) => {
  const newsApi = new NewsAPI();
  await newsApi.DislikeNews(guid);
};

export const useLikeNews = (newsId: string) => {
  const queryClient = useQueryClient();
  const { newsOptions } = useGlobals();
  return useMutation({
    mutationFn: likeNews,
    onMutate: () => {
      queryClient.cancelQueries(newsKeys.list());
      queryClient.setQueriesData<InfiniteData<InfinityList<NewsResponse>>>(['news', 'list'], (prev) => {
        const containsNews = prev?.pages.some((page) => page.result.some((news) => news.GUID === newsId));
        if (prev && containsNews) {
          return {
            ...prev,
            pages: prev.pages.map((page) => {
              return {
                ...page,
                result: page.result.map((news) =>
                  news.GUID === newsId
                    ? {
                        ...news,
                        LikeStatus: true,
                        NumberOfLikes: news.NumberOfLikes + 1,
                      }
                    : news,
                ),
              };
            }),
          };
        }
        return undefined;
      });
      // queryClient.setQueriesData<InfiniteData<InfinityList<NewsResponse>>>(
      //   ['news', 'list'],
      //   (prev) => {
      //     const containsTodo = prev?.pages.some((page) =>
      //       page.result.some((news) => news.GUID === newsId),
      // ),
      //     );
      //     if (prev && containsTodo) {
      //       return {
      //         ...prev,
      //         pages: prev.pages.map((page) =>
      //           page.map((list) => {
      //             return {
      //               ...list,
      //               result: list.result.map((news) =>
      //                 news.GUID === newsId
      //                   ? { ...news, LikeStatus: true }
      //                   : news,
      //               ),
      //             };
      //           }),
      //         ),
      //       };
      //     }
      //     return undefined;
      //   },
      // );
    },
    onSettled: () => {
      queryClient.invalidateQueries(newsKeys.list());
    },
  });
};

export const useDislikeNews = (newsId: string) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: dislikeNews,
    onMutate: () => {
      queryClient.cancelQueries(newsKeys.list());
      queryClient.setQueriesData<InfiniteData<InfinityList<NewsResponse>>>(['news', 'list'], (prev) => {
        const containsNews = prev?.pages.some((page) => page.result.some((news) => news.GUID === newsId));
        if (prev && containsNews) {
          return {
            ...prev,
            pages: prev.pages.map((page) => {
              return {
                ...page,
                result: page.result.map((news) =>
                  news.GUID === newsId
                    ? {
                        ...news,
                        LikeStatus: false,
                        NumberOfLikes: news.NumberOfLikes - 1,
                      }
                    : news,
                ),
              };
            }),
          };
        }
        return undefined;
      });
    },
    onSettled: () => {
      queryClient.invalidateQueries(newsKeys.list());
    },
  });
};
