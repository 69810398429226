import { NewsAPI } from 'src/services/api/newsApi';
import { ChatRoomMessageAPI } from 'src/services/api/chatRoomMessageApi';
import { useMutation } from '@tanstack/react-query';
import { EmployeeAPI } from 'src/services/api/employeeApi';

const newsApi = new NewsAPI();
const employeeApi = new EmployeeAPI();
const chatApi = new ChatRoomMessageAPI();
const postImage = async (imageFormData: File) => {
  const formDataImage = new FormData();
  formDataImage.append('fileData', imageFormData);
  const res = await newsApi.CreateFormDataImage(formDataImage);
  return res;
};
const postEmployeeProfileImage = async (imageFormData: File) => {
  const formDataImage = new FormData();
  formDataImage.append('fileData', imageFormData);
  const res = await employeeApi.CreateFormDataImage(formDataImage);
  return res;
};
const postDocuments = async (documentsFormData: File) => {
  const formDataDoc = new FormData();
  formDataDoc.append('fileData', documentsFormData);
  const res = newsApi.CreateFormDataDocument(formDataDoc);
  return res;
};
const postChatsImage = async (imageFormData: File) => {
  const formDataImage = new FormData();
  formDataImage.append('fileData', imageFormData);
  const res = await chatApi.CreateFormDataImage(formDataImage);
  return res;
};
const postChatsDocuments = async (documentsFormData: File) => {
  const formDataDoc = new FormData();
  formDataDoc.append('fileData', documentsFormData);
  const res = chatApi.CreateFormDataDocument(formDataDoc);
  return res;
};
export const usePostNewsImage = () => {
  return useMutation({
    mutationFn: postImage,
  });
};
export const usePostEmployeeProfileImage = () => {
  return useMutation({
    mutationFn: postEmployeeProfileImage,
  });
};
export const usePostNewsDocs = () => {
  return useMutation({
    mutationFn: postDocuments,
  });
};
export const usePostChatImage = () => {
  return useMutation({
    mutationFn: postChatsImage,
  });
};
export const usePostChatDocs = () => {
  return useMutation({
    mutationFn: postChatsDocuments,
  });
};
