// COLOR SCHEME

type ColorScheme = {
  PRIMARY: string;
  SECONDARY: string;
  ACCENT_DARK: string;
  ACCENT_LIGHT: string;
};

export const Colors = {
  // Anpassas med tema
  PRIMARY: '#F79D90', // Default korall
  SECONDARY: '#f6ada2', // Default ljus korall (osäker om den appliceras nånstans)
  ACCENT_DARK: '#3A2058', // Defualt lila (knappar)
  ACCENT_LIGHT: '#E45570', // Default rosa (tex notisalert, gillaknapp)

  // Samma för alla
  TERTIARY: '#061727', // Default mörkblå (t ex bakgrund på mina egna chattbubblor)
  BACKGROUND: '#FAF4F1', // Default beige-rosa

  WARNING: '#E45570',
  YELLOW: '#f2c753',
  GREEN: '#7ac5ad',
  RED: '#E57361',
  GRAY_FAIR: '#F3F3F3',
  GRAY_LIGHT: '#F2F2F2',
  GRAY_BORDER: '#D4D4D4',
  GRAY_MEDIUM: '#B5B5B5',
  GRAY_MEDIUM_DARK: '#8d8d8d',
  GRAY_DARK: '#676767',
  BLACK: '#061727',
  WHITE: '#FFFFFF',
};

export const updateColors = (newColors: ColorScheme | null) => {
  if (!newColors) {
    // Återställ till default-färger
    Object.assign(Colors, {
      PRIMARY: '#F79D90',
      SECONDARY: '#f6ada2',
      ACCENT_DARK: '#3A2058',
      ACCENT_LIGHT: '#E45570',
    });
  } else {
    // Uppdatera endast de färger som tillhandahålls
    Object.assign(Colors, newColors);
  }
};
