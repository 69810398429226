import { useQuery, useQueryClient } from '@tanstack/react-query';
import { newsKeys } from 'src/modules/home/hooks/newsKeys';
import { NewsAPI } from 'src/services/api/newsApi';
import { NewsResponse } from 'src/services/api/response/newsResponse';

export const getNewsById = async (guid: string) => {
  const newsApi = new NewsAPI();
  const res: NewsResponse = await newsApi.Get(guid);
  return res;
};

export const useFetchNewsById = (guid: string) => {
  const queryClient = useQueryClient();
  return useQuery({
    queryKey: newsKeys.byId(guid),
    queryFn: () => getNewsById(guid),

    initialData: () => {
      return queryClient.getQueryData<NewsResponse[]>(newsKeys.all)?.find((news) => news.GUID === guid);
    },
  });
};
