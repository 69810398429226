import { useCallback, useEffect } from 'react';
import { ChatRoomAPI } from 'src/services/api/chatRoomApi';
import { ChatRoomOptions } from 'src/services/api/options/chatOptions';
import { useGlobals } from 'src/services/contexts/appContext';
import ConsoleHelper from 'src/services/helpers/consoleHelper';
import { Badge } from '@mui/material';
import { Sms } from '@mui/icons-material';
import { Colors } from 'src/shared/components/styles/colors';

const ChatNotificationBadge = () => {
  // Global states
  const { unreadChats, setUnreadChats } = useGlobals();

  const fetchChatroom = useCallback(async () => {
    const reqOptions = new ChatRoomOptions();
    const chatRoomApi = new ChatRoomAPI();

    try {
      const res = await chatRoomApi.GetAll(reqOptions);
      if (res) {
        const allChatrooms = res.Results;
        const unreadChatrooms = allChatrooms.filter((room) => !room.IsRead);
        setUnreadChats(unreadChatrooms.length);
      }
    } catch (error) {
      ConsoleHelper.log(error);
    }
  }, [setUnreadChats]);

  useEffect(() => {
    fetchChatroom();
    // ConsoleHelper.log('number in chatNot', unreadChats);
  }, [fetchChatroom]);

  return (
    <>
      <Badge
        badgeContent={unreadChats}
        max={99}
        overlap='circular'
        sx={{
          '& .MuiBadge-badge': {
            backgroundColor: Colors.ACCENT_LIGHT,
          },
        }}
      >
        <Sms />
      </Badge>
    </>
  );
};

export default ChatNotificationBadge;
