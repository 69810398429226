import { Box, CardActions, Collapse, List, Typography } from '@mui/material';
import { NewsResponse } from 'src/services/api/response/newsResponse';
import { useEffect, useState } from 'react';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';
import { NewsCategoryEnum } from 'src/shared/enums/newsCategory.enum';
import LockClockIcon from '@mui/icons-material/LockClock';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import ReadConfirmButton from './readConfirmButton';
import ActivityButtons from './activityButtons';
import ParticipantsButton from './participantsButton';
import { useFetchNewsById } from '../hooks/queries/useFetchNewsById';
import ViewFile from './viewFile';
import { useMarkAsReadAndUnderstood } from '../hooks/mutations/useMarkAsReadAndUnderstood';

type NewsDetailListItemProps = {
  isActive: boolean;
  newsGuid: string;
};

export default function NewsDetailListItem(props: NewsDetailListItemProps) {
  const { isActive, newsGuid } = props;
  const { t } = useTranslation(['common', 'news']);
  const [newsItem, setNewsItem] = useState<NewsResponse>();

  const markAsReadAndConfirmed = useMarkAsReadAndUnderstood();

  const fetchNewsItem = useFetchNewsById(newsGuid);

  const markAsConfirmed = async (item: NewsResponse) => {
    markAsReadAndConfirmed.mutate(item.GUID);
  };

  useEffect(() => {
    if (fetchNewsItem.data) {
      setNewsItem(fetchNewsItem.data);
    }
  }, [fetchNewsItem.data]);

  return (
    <>
      <Collapse
        in={isActive}
        timeout={300}
        easing={{
          enter: 'cubic-bezier(0.4, 0.0, 0.2, 1)',
          exit: 'linear',
        }}
        unmountOnExit
      >
        <List sx={{ display: 'flex', flexDirection: 'column', px: 0, mt: 2 }}>
          {newsItem?.PDF && <ViewFile doc={newsItem?.PDF} />}
          {newsItem?.PDFs && newsItem?.PDFs.map((doc) => <ViewFile key={doc} doc={doc} />)}
        </List>
      </Collapse>

      <Collapse
        in={isActive}
        timeout={300}
        easing={{
          enter: 'cubic-bezier(0.4, 0.0, 0.2, 1)',
          exit: 'linear',
        }}
        sx={{ px: 1 }}
      >
        {newsItem && newsItem.ShowConfirmAction && (
          <>
            <ReadConfirmButton readStatus={newsItem.ConfirmedByMe} onClick={() => markAsConfirmed(newsItem)} />
          </>
        )}
        {newsItem?.Category === NewsCategoryEnum.Activities && (
          <>
            {newsItem?.ActivityDate && (
              <Box display='flex' alignItems='center' flexDirection='row' px={1} mb={1}>
                <CalendarMonthIcon />
                <Typography variant='h6' pl={0.5}>
                  {t('news:activityDate')} {dayjs(newsItem?.ActivityDate).format('dddd')} {dayjs(newsItem?.ActivityDate).format('DD MMM YYYY')} kl{' '}
                  {dayjs(newsItem?.ActivityDate).format('HH:mm')}
                </Typography>
              </Box>
            )}
            {newsItem?.DeadlineDate && (
              <Box display='flex' alignItems='center' flexDirection='row' px={1} mb={1}>
                <LockClockIcon />
                <Typography variant='h6' pl={0.5}>
                  {t('news:deadlineDate')} {dayjs(newsItem?.DeadlineDate).format('YYYY-MM-DD')}
                </Typography>
              </Box>
            )}
          </>
        )}
        <CardActions
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          <Box>{newsItem?.Category === NewsCategoryEnum.Activities && <ActivityButtons newsItem={newsItem} />}</Box>
          <Box>{newsItem && newsItem?.Participants.length > 0 && <ParticipantsButton items={newsItem?.Participants} />}</Box>
        </CardActions>
      </Collapse>
    </>
  );
}
