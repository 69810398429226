import { createUserManager } from 'redux-oidc';

import { UserManagerSettings } from 'oidc-client';

const hostDomain = `${window.location.protocol}//${window.location.hostname}${window.location.port ? `:${window.location.port}` : ''}`;

const userManagerConfig: UserManagerSettings = {
  client_id: process.env.REACT_APP_IDENTITY_CLIENT_ID,
  redirect_uri: `${hostDomain}${process.env.REACT_APP_IDENTITY_REDIRECT_PAGE}`,
  response_type: process.env.REACT_APP_IDENTITY_RESPONSE_TYPE,
  scope: process.env.REACT_APP_IDENTITY_SCOPE,
  authority: process.env.REACT_APP_IDENTITY_AUTHORITY,
  post_logout_redirect_uri: `${hostDomain}${process.env.REACT_APP_IDENTITY_POST_LOGOUT_REDIRECT_PAGE}`,
  silent_redirect_uri: `${hostDomain}${process.env.REACT_APP_IDENTITY_SILENT_REDIRECT_PAGE}`,
  automaticSilentRenew: true,
  filterProtocolClaims: true,
  loadUserInfo: true,
  max_age: 86400, // Force reauth every 24h
};

const userManager = createUserManager(userManagerConfig);

export default userManager;
