import { useState } from 'react';
import { Button, Tooltip } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { NewsResponse } from 'src/services/api/response/newsResponse';
import { EditNewsModal } from 'src/modules/home/components/editNewsModal';

type NewsEditButtonProps = {
  newsItem: NewsResponse;
  unPublish: () => void;
  modalOpen: boolean;
  setModalOpen: (arg: boolean) => void;
};

const NewsEditButton = (props: NewsEditButtonProps) => {
  const { newsItem, unPublish, modalOpen, setModalOpen } = props;
  // const [modalOpen, setModalOpen] = useState(false);
  const { t } = useTranslation('news');

  const onClickOpenEditNewsModal = () => {
    setModalOpen(true);
  };

  return (
    <>
      <Tooltip title={t('edit')}>
        <Button fullWidth variant='outlined' sx={{ mt: 1 }} onClick={onClickOpenEditNewsModal}>
          {t('edit')}
        </Button>
      </Tooltip>

      {modalOpen && (
        <EditNewsModal unPublish={unPublish} newsItem={newsItem} modalOpen={modalOpen} setModalOpen={(arg: boolean) => setModalOpen(arg)} />
      )}
    </>
  );
};

export default NewsEditButton;
