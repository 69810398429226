import { useQuery } from '@tanstack/react-query';
import { progressKeys } from 'src/modules/progress/hooks/progressKeys';
import { EmployeeAPI } from 'src/services/api/employeeApi';
import { PagedResult } from 'src/services/api/helpers/pagedResult';
import { EmployeeResponse } from 'src/services/api/response/employeeResponse';
import { ProgressResponse } from 'src/services/api/response/progressResponse';
import { useGlobals } from 'src/services/contexts/appContext';

const progressApi = new EmployeeAPI();

export const getProgress = async (employeeGuid: string): Promise<ProgressResponse[]> => {
  const res: PagedResult<ProgressResponse> = await progressApi.GetMyProgress(employeeGuid);
  return res.Results;
};

const getProgressByCategory = async (employeeGuid: string, categoryGuid: string): Promise<ProgressResponse[]> => {
  const res: PagedResult<ProgressResponse> = await progressApi.GetMyProgressByCategory(employeeGuid, categoryGuid);
  return res.Results;
};

export const useFetchProgress = () => {
  const { loggedInUser } = useGlobals();

  return useQuery({
    queryKey: progressKeys.byEmployee(loggedInUser?.GUID ?? ''),
    queryFn: () => loggedInUser && getProgress(loggedInUser?.GUID),
  });
};

export const useFetchProgressByCategory = (categoryGuid: string) => {
  const { loggedInUser } = useGlobals();

  return useQuery({
    queryKey: progressKeys.byCategory(loggedInUser?.GUID ?? '', categoryGuid),
    queryFn: () => loggedInUser && getProgressByCategory(loggedInUser.GUID, categoryGuid),
  });
};
