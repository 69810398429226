import { Button, useMediaQuery, useTheme, Menu, MenuItem, Box } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import { Language as LanguageIcon } from '@mui/icons-material';
import { Colors } from 'src/shared/components/styles/colors';
import { useGlobals } from 'src/services/contexts/appContext';

export interface LanguageItem {
  name: string;
  value: 'sv' | 'en' | 'helsinge';
}

export const SwitchLanguageButton = () => {
  // const { i18n } = useTranslation(['common']);
  const { setLanguage, language } = useGlobals();
  const { t } = useTranslation();
  const availableLanguages: LanguageItem[] = [
    {
      name: t('language.swedish'),
      value: 'sv',
    },
    {
      name: t('language.english'),
      value: 'en',
    },
    {
      name: t('language.helsinge'),
      value: 'helsinge',
    },
  ];
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const theme = useTheme();
  const isTablet = useMediaQuery(theme.breakpoints.down('md'));

  const changeLanguage = (lng: string) => {
    setAnchorEl(null);
    setLanguage(lng);
  };

  return (
    <>
      <Button
        sx={{
          color: isTablet ? Colors.ACCENT_DARK : 'inherit',
          '&:hover': {
            backgroundColor: isTablet ? 'transparent' : 'default',
          },
        }}
        aria-controls={open ? 'basic-menu' : undefined}
        aria-haspopup='true'
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
      >
        <Box sx={{ pr: 1, pt: 0.5 }}>{language.toUpperCase()}</Box>
        <LanguageIcon />
      </Button>
      <Menu
        id='basic-menu'
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        {availableLanguages.map((item) => {
          return (
            <MenuItem key={item.value} onClick={() => changeLanguage(item.value)} sx={{ fontWeight: item.value === language ? 600 : 200 }}>
              {item.name}
            </MenuItem>
          );
        })}
      </Menu>
    </>
  );
};
