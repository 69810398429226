import { AxiosInstance, AxiosInstanceFormData } from 'src/services/axios/interceptors';
import { BaseAPI } from 'src/services/api/baseApi';
import { NewsOptions } from 'src/services/api/options/newsOptions';
import { NewsRequest } from 'src/services/api/request/newsRequest';
import { NewsResponse } from 'src/services/api/response/newsResponse';
import { CommentDTO } from 'src/services/api/models';

export class NewsAPI extends BaseAPI<NewsResponse, NewsOptions, NewsRequest> {
  constructor() {
    super('News');
  }

  public AddParticipant = (guid: string, nrOfParticipants: string) =>
    AxiosInstance.post(`${this.controllerPath}/${guid}/participant/${nrOfParticipants}`);

  public RemoveParticipant = (guid: string) => AxiosInstance.delete(`${this.controllerPath}/${guid}/participant`);

  public LikeNews = (guid: string) => AxiosInstance.post(`${this.controllerPath}/${guid}/like`);

  public DislikeNews = (guid: string) => AxiosInstance.delete(`${this.controllerPath}/${guid}/like`);

  public AddComment = (guid: string, req: CommentDTO) => AxiosInstance.post(`${this.controllerPath}/${guid}/comment`, req);

  public RemoveComment = (guid: string, commentGuid: string) =>
    AxiosInstance.delete(`${this.controllerPath}/${guid}/comment?commentGuid=${commentGuid}`);

  public ReadNews = (guid: string) => AxiosInstance.post(`${this.controllerPath}/${guid}/read`);

  public MarkAsConfirmed = (guid: string) => AxiosInstance.post(`${this.controllerPath}/${guid}/confirm`);

  public Unpublish = (guid: string) => AxiosInstance.post(`${this.controllerPath}/${guid}/unpublish`);

  public GetNewsCategories = () => AxiosInstance.get(`${this.controllerPath}/categories`);

  public CreateFormDataImage = (req: FormData) =>
    AxiosInstanceFormData.post(`${this.controllerPath}/uploadMediaImage`, req, {
      transformRequest: (data) => {
        return data;
      },
    });

  public CreateFormDataDocument = (req: FormData) =>
    AxiosInstanceFormData.post(`${this.controllerPath}/uploadMediaDocuments`, req, {
      transformRequest: (data) => {
        return data;
      },
    });
}
