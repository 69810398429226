import { PrefetchFn, PrefetchRoutes, Routes } from 'src/services/helpers/prefetch';
import { RoutesEnum } from 'src/shared/enums/routes.enum';

export interface MenuItems {
  label: string;
  link: RoutesEnum;
  Icon: string;
  onHover?: PrefetchFn<void>;
}

export const headerMenuItems = (t: (n: string) => string, prefetch: PrefetchRoutes<Routes>): MenuItems[] => [
  {
    label: t('screens.news'),
    link: RoutesEnum.Start,
    Icon: 'Error',
  },
  {
    label: t('screens.calendar'),
    link: RoutesEnum.Calendar,
    Icon: 'DateRange',
    onHover: prefetch.calendar,
  },
  {
    label: t('screens.development'),
    link: RoutesEnum.Progress,
    Icon: 'Star',
    onHover: prefetch.progress,
  },
  {
    label: t('screens.employees'),
    link: RoutesEnum.Employee,
    Icon: 'Groups',
  },
  // {
  //   label: t('screens.career'),
  //   link: RoutesEnum.Career,
  //   Icon: 'WorkOutline',
  //   onHover: prefetch.career,
  // },
  {
    label: t('screens.forms'),
    link: RoutesEnum.Form,
    Icon: 'ArticleOutlined',
  },
];

export const sidebarMenuItems = (t: (n: string) => string): MenuItems[] => [
  {
    label: t('screens.lunch'),
    link: RoutesEnum.AdminLunch,
    Icon: 'FoodBankOutlined',
  },
];
