import { useState, useCallback, useEffect } from 'react';
import { Typography } from '@mui/material';
import ModalTemplate from 'src/shared/templates/modalTemplate';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';
import ConsoleHelper from 'src/services/helpers/consoleHelper';
import { NotificationResponse } from 'src/services/api/response/notificationResponse';
import { AbsenceAPI } from 'src/services/api/absenceApi';
import { AbsenceResponse } from 'src/services/api/response/absenceResponse';
import { useIsMounted } from 'src/services/helpers/useIsMounted';
import { HandleApplicationButtons } from 'src/modules/calendar/components/handleApplicationButtons';
import { Colors } from 'src/shared/components/styles/colors';

type ManagerHandleLeaveModalProps = {
  notice: NotificationResponse | undefined;
  modalOpen: boolean;
  setModalOpen: (arg: boolean) => void;
};

export const ManagerHandleLeaveModal = (props: ManagerHandleLeaveModalProps) => {
  const { notice, modalOpen, setModalOpen } = props;
  const { t } = useTranslation('calendar');

  const isMounted = useIsMounted();

  const [loading, setLoading] = useState<boolean>(false);
  const [absence, setAbsence] = useState<AbsenceResponse | undefined>(undefined);

  const fetchAbsenceToHandle = useCallback(async () => {
    const absenceapi = new AbsenceAPI();
    setLoading(true);
    try {
      const res = await absenceapi.GetAbsenceById(notice?.id_for_type_post ?? '');
      if (isMounted()) {
        ConsoleHelper.log(res);
        setAbsence(res);
      }
    } catch (error) {
      if (isMounted()) {
        ConsoleHelper.log(error);
      }
    } finally {
      if (isMounted()) {
        setLoading(false);
      }
    }
  }, [isMounted, notice?.id_for_type_post]);

  useEffect(() => {
    fetchAbsenceToHandle();
  }, [fetchAbsenceToHandle]);

  return (
    <ModalTemplate
      isOpen={modalOpen}
      onClose={() => setModalOpen(false)}
      isLoading={loading}
      title={`${notice?.title}`}
      subtitle={
        <Typography variant='subtitle1' sx={{ color: Colors.GRAY_DARK, fontSize: '14px' }}>
          {dayjs(notice?.date).format('D MMMM YYYY')}
        </Typography>
      }
      modalWidth='40%'
      content={
        <>
          {absence && (
            <>
              <>
                <Typography fontWeight='bold'>{t('name')}:</Typography>
                <Typography>{absence?.Person}</Typography>
              </>
              <>
                <Typography fontWeight='bold'>{t('content.absenceReason')}:</Typography>
                <Typography>{absence?.Reason}</Typography>
              </>
              <>
                <Typography fontWeight='bold'>{t('from')}</Typography>
                <Typography>
                  {dayjs(absence?.From).format('ddd DD MMM YYYY')} kl {dayjs(absence?.From).format('HH:mm')}
                </Typography>
              </>
              <>
                <Typography fontWeight='bold'>{t('to')}</Typography>
                <Typography>
                  {dayjs(absence?.To).format('ddd DD MMM YYYY')} kl {dayjs(absence?.To).format('HH:mm')}
                </Typography>
              </>
              {absence?.UserComment && absence?.UserComment?.length > 0 ? (
                <>
                  <Typography fontWeight='bold'>{t('content.comment')}:</Typography>
                  <Typography>{absence?.UserComment ?? 'Ingen kommentar'}</Typography>
                </>
              ) : null}
              {absence && <HandleApplicationButtons absence={absence} closeModal={() => setModalOpen(false)} />}
            </>
          )}
        </>
      }
    />
  );
};
