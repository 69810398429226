import { keyframes } from '@emotion/react';
import { FiberManualRecord } from '@mui/icons-material';
import { Colors } from '../styles/colors';

const NotificationDot = () => {
  const pulse = keyframes`
  0% { transform: scale(1, 1) }
  50% { transform: scale(0.5, 0.5) }
  100% { transform: scale(1, 1) }
`;
  return (
    <>
      <FiberManualRecord
        sx={{
          fontSize: '13px',
          color: Colors.ACCENT_LIGHT,
          animation: `${pulse} 1s linear infinite`,
        }}
      />
    </>
  );
};

export default NotificationDot;
