import { Button } from '@mui/material';

import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useTranslation } from 'react-i18next';
import { NewsResponse } from 'src/services/api/response/newsResponse';
import ConsoleHelper from 'src/services/helpers/consoleHelper';
import { ControlledTextInput } from 'src/shared/components/form/controlledTextInput';
import { Colors } from 'src/shared/components/styles/colors';
import { useAddComment } from 'src/modules/home/hooks/mutations/useComments';
import { useGlobals } from 'src/services/contexts/appContext';

interface NewsComment {
  comment: string;
}

type NewsCommentInputProps = {
  newsItem: NewsResponse;
};

export const NewsCommentSchema = (t: (n: string) => void) =>
  yup.object().shape({
    comment: yup.string().required(`${t('common:requiredField')}`),
  });

const NewsCommentInput = (props: NewsCommentInputProps) => {
  const { newsItem } = props;
  const { t } = useTranslation('news');
  const { loggedInUser } = useGlobals();

  // useMutation
  const addComment = useAddComment();

  const formMethods = useForm<NewsComment>({
    resolver: yupResolver(NewsCommentSchema(t)),
    defaultValues: {
      comment: '',
    },
  });
  const { handleSubmit, reset } = formMethods;

  const formSubmit = async (data: NewsComment) => {
    ConsoleHelper.log('Comment', data);
    addComment.mutate(
      {
        GUID: newsItem.GUID,
        Content: data.comment,
        Name: `${loggedInUser?.Firstname} ${loggedInUser?.Lastname}`,
      },
      {
        onSuccess: () => {
          reset();
        },
      },
    );
  };

  return (
    <FormProvider {...formMethods}>
      <form onSubmit={handleSubmit(formSubmit)}>
        <ControlledTextInput
          sx={{
            borderRadius: 1,
            backgroundColor: Colors.GRAY_FAIR,
            mb: 2,
          }}
          name='comment'
          placeholder={t('post')}
        />
        <Button disabled={addComment.isLoading} variant='contained' type='submit' sx={{ mb: 2 }}>
          {t('send')}
        </Button>
      </form>
    </FormProvider>
  );
};

export default NewsCommentInput;
