import { Controller, useFormContext, useFormState } from 'react-hook-form';
import { DesktopDatePicker, MobileDatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { useTheme } from '@mui/material/styles';
import { FormLabel, TextField, FormControlLabel, useMediaQuery, Typography, Box } from '@mui/material';
import dayjs from 'dayjs';
import sv from 'dayjs/locale/sv';

type ControlledDatePickerProps = {
  name: string;
  label?: string;
  infoContent?: string;
  info?: string;
  marginTop?: string;
  paddingBottom?: string;
  disabled?: any;
};

const ControlledDatePicker = (props: ControlledDatePickerProps) => {
  const { name, label, infoContent, info, marginTop, paddingBottom, disabled } = props;
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const { control } = useFormContext();
  const { errors } = useFormState();

  let isError = false;
  let errorMessage: string | any = '';
  if (errors && Object.prototype.hasOwnProperty.call(errors, name)) {
    isError = true;
    errorMessage = errors[name]?.message;
  }

  return (
    <>
      <FormLabel
        sx={{
          pt: 1,
          color: 'text.primary',
          display: 'flex',
          mt: marginTop,
        }}
      >
        <Typography variant='subtitle2' sx={{ mr: 0.5, mb: 0.5 }}>
          {label}
        </Typography>
      </FormLabel>
      <Controller
        name={name}
        control={control}
        render={({ field }) => (
          <FormControlLabel
            control={
              <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={sv}>
                {isMobile ? (
                  <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                    <MobileDatePicker
                      inputFormat='YYYY-MM-DD'
                      mask='____-__-__'
                      minDate={dayjs('2017-01-01')}
                      disabled={disabled ? true : undefined}
                      value={field.value}
                      onChange={(e) => field.onChange(e)}
                      renderInput={(params) => <TextField size='small' sx={{ mx: '3px', px: 1 }} {...params} />}
                    />
                  </Box>
                ) : (
                  <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                    <DesktopDatePicker
                      inputFormat='YYYY-MM-DD'
                      mask='____-__-__'
                      minDate={dayjs('2017-01-01')}
                      onChange={(e) => field.onChange(e)}
                      disabled={disabled ? true : undefined}
                      value={field.value}
                      renderInput={(params) => (
                        <TextField error={isError} helperText={isError ? errorMessage : infoContent} sx={{ mx: '3px', px: 1 }} {...params} />
                      )}
                    />
                  </Box>
                )}
              </LocalizationProvider>
            }
            key={label}
            label=''
          />
        )}
      />
      <Typography
        sx={{
          color: theme.palette.grey[500],
          fontStyle: 'italic',
          pb: paddingBottom,
        }}
        variant='subtitle1'
      >
        {info}
      </Typography>
    </>
  );
};
ControlledDatePicker.defaultProps = {
  label: undefined,
  infoContent: undefined,
  info: undefined,
  marginTop: '20px',
  paddingBottom: '0px',
  disabled: null,
};

export default ControlledDatePicker;
