import { Controller, useFormContext, useFormState } from 'react-hook-form';
import { FormHelperText, useMediaQuery, Typography, Autocomplete, TextField } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { DepartmentResponse } from 'src/services/api/response/departmentResponse';
import { useTranslation } from 'react-i18next';

type ControlledSelectDepartmentProps = {
  name: string;
  label?: string;
  selectItems: DepartmentResponse[];
  showHelperText?: boolean;
};

const ControlledSelectDepartment = (props: ControlledSelectDepartmentProps) => {
  const { name, label, selectItems, showHelperText } = props;
  const { t } = useTranslation();
  const { control } = useFormContext();
  const { errors } = useFormState();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  let isError = false;
  let errorMessage: string | any = '';
  if (errors && Object.prototype.hasOwnProperty.call(errors, name)) {
    isError = true;
    errorMessage = errors[name]?.message;
  }

  return (
    <>
      {label && (
        <Typography variant='subtitle2' marginTop={1.5} marginBottom={0.5}>
          {label}
        </Typography>
      )}
      <Controller
        control={control}
        name={name}
        render={({ field }) => (
          <>
            <Autocomplete
              multiple
              id={name}
              options={selectItems}
              size={isMobile ? 'small' : 'medium'}
              autoHighlight
              fullWidth
              value={field.value}
              getOptionLabel={(option) => option.Name}
              isOptionEqualToValue={(option, item) => option.ID === item.ID || option.GUID === item.GUID}
              onChange={(event, selectedOptions) => {
                field.onChange(selectedOptions);
              }}
              renderInput={(params) => {
                return (
                  <TextField
                    {...params}
                    margin='dense'
                    variant='outlined'
                    placeholder={t('news:departments')}
                    error={isError}
                    helperText={isError ?? errorMessage}
                    type='text'
                    fullWidth
                    inputProps={{
                      ...params.inputProps,
                      autoComplete: true.toString(),
                    }}
                  />
                );
              }}
            />
            <FormHelperText sx={{ color: 'error.main' }}>{isError ? errorMessage : ''}</FormHelperText>
            {showHelperText && (
              <Typography sx={{ color: theme.palette.grey[500], fontStyle: 'italic' }} variant='subtitle1'>
                {t('news:departmentsInfo')}
              </Typography>
            )}
          </>
        )}
      />
    </>
  );
};
ControlledSelectDepartment.defaultProps = {
  label: null,
  showHelperText: true,
};
export default ControlledSelectDepartment;
