import { Dispatch } from 'react';

export const SET_DOMAIN = 'SET_DOMAIN';

export const setDomain = (domain: string) => {
  return async (dispatch: Dispatch<AppDispatchTypes>) => {
    dispatch({ type: SET_DOMAIN, payload: domain });
  };
};

export interface SetDomain {
  type: typeof SET_DOMAIN;
  payload: string;
}

export type AppDispatchTypes = SetDomain;
