import { combineReducers } from 'redux';
import { reducer as oidcReducer } from 'redux-oidc';
import { appReducer } from './appReducer';

const rootReducer = combineReducers({
  oidc: oidcReducer,
  appState: appReducer,
});
export default rootReducer;

export type RootStateType = ReturnType<typeof rootReducer>;
