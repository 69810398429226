import { CloseRounded } from '@mui/icons-material';
import { Box, IconButton, Typography } from '@mui/material';

type ModalHeaderTemplateProps = {
  title: string;
  subtitle?: JSX.Element;
  onClose: () => void;
};

export default function ModalHeaderTemplate(props: ModalHeaderTemplateProps) {
  const { title, subtitle, onClose } = props;
  return (
    <Box display='flex' flexDirection='column' mb={2}>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <Typography variant='h1'>{title}</Typography>
        <IconButton aria-label='close-modal' onClick={onClose} sx={{ color: 'primary.main' }}>
          <CloseRounded />
        </IconButton>
      </Box>
      {subtitle && <>{subtitle}</>}
    </Box>
  );
}

ModalHeaderTemplate.defaultProps = {
  subtitle: undefined,
};
