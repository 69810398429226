import { Check } from '@mui/icons-material';
import { Button } from '@mui/material';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import ConfirmDialogModal from 'src/shared/components/atoms/confirmDialog';
import { Colors } from 'src/shared/components/styles/colors';

type ReadConfirmButtonProps = {
  readStatus: boolean;
  onClick: () => void;
};
const ReadConfirmButton = (props: ReadConfirmButtonProps) => {
  const { readStatus, onClick } = props;
  const { t } = useTranslation();

  // Local State
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);

  return readStatus ? (
    <Button variant='contained' disabled sx={{ bgcolor: Colors.GRAY_MEDIUM, mx: 1, mb: 3 }}>
      {t('news:readAndUnderstood')} <Check sx={{ ml: 1 }} />
    </Button>
  ) : (
    <>
      <Button
        variant='contained'
        sx={{
          mx: 1,
          mb: 3,
        }}
        onClick={() => {
          setOpenConfirmDialog(true);
        }}
      >
        {t('news:readAndUnderstood')}
      </Button>
      <ConfirmDialogModal
        open={openConfirmDialog}
        dialogTitle={t('common:confirm')}
        dialogText={t('news:confirmReadAndUnderstand')}
        handleClose={() => setOpenConfirmDialog(false)}
        confirmTextButton={t('common:yes')}
        cancelTextButton={t('common:no')}
        confirmButtonClick={onClick}
      />
    </>
  );
};

export default ReadConfirmButton;
