/* eslint-disable no-nested-ternary */
import { Favorite, FavoriteBorder } from '@mui/icons-material';
import { keyframes } from '@emotion/react';
import { Button, Typography } from '@mui/material';
import { NewsResponse } from 'src/services/api/response/newsResponse';
import { Colors } from 'src/shared/components/styles/colors';
import { useTranslation } from 'react-i18next';

import { useDislikeNews, useLikeNews } from 'src/modules/home/hooks/mutations/useNewsLikeStatus';
import { getNewsById, useFetchNewsById } from 'src/modules/home/hooks/queries/useFetchNewsById';
import { useQueryClient } from '@tanstack/react-query';
import { newsKeys } from 'src/modules/home/hooks/newsKeys';

type NewsLikeButtonProps = {
  item: NewsResponse;
  fromModal?: boolean;
};

const NewsLikeButton = (props: NewsLikeButtonProps) => {
  const { item, fromModal } = props;
  const { t } = useTranslation(['news']);
  const queryClient = useQueryClient();

  // useMutations
  const likeNews = useLikeNews(item.GUID);
  const dislikeNews = useDislikeNews(item.GUID);

  const onLikeNewsClicked = async (i: NewsResponse) => {
    if (i.LikeStatus === false) {
      likeNews.mutate(i.GUID);
    } else {
      dislikeNews.mutate(i.GUID);
    }
  };

  const onHoverHandler = async () => {
    await queryClient.prefetchQuery({
      queryKey: newsKeys.byId(item.GUID),
      queryFn: () => getNewsById(item.GUID),
    });
  };

  const pulse = keyframes`
  0% { transform: scale(1, 1) }
  50% { transform: scale(0.5, 0.5) }
  100% { transform: scale(1, 1) }
`;

  return (
    <Button
      variant='text'
      sx={{
        color: Colors.GRAY_DARK,
        textTransform: 'none',
      }}
      onMouseEnter={onHoverHandler}
      onClick={() => onLikeNewsClicked(item)}
      disabled={likeNews.isLoading || dislikeNews.isLoading}
    >
      <Typography
        variant='subtitle2'
        sx={{
          display: 'flex',
          alignItems: 'center',
          color: Colors.GRAY_DARK,
        }}
      >
        {likeNews.isLoading || dislikeNews.isLoading ? (
          <Favorite
            sx={{
              color: 'primary.main',
              mr: 1,
              animation: `${pulse} .3s linear infinite`,
            }}
          />
        ) : item.LikeStatus ? (
          <Favorite
            sx={{
              color: 'primary.main',
              mr: 1,
            }}
          />
        ) : (
          <FavoriteBorder
            sx={{
              mr: 1,
            }}
          />
        )}

        <Typography
          sx={{
            display: 'flex',
            alignItems: 'center',
            color: Colors.GRAY_DARK,
          }}
        >
          {t('Like')}
        </Typography>
      </Typography>
      {fromModal && (
        <Typography
          variant='subtitle2'
          sx={{
            display: 'flex',
            alignItems: 'center',
            color: Colors.ACCENT_DARK,
            pl: '5px',
            fontWeight: 900,
          }}
        >
          ({item?.NumberOfLikes})
        </Typography>
      )}
    </Button>
  );
};

NewsLikeButton.defaultProps = {
  fromModal: false,
};

export default NewsLikeButton;
