/* eslint-disable no-underscore-dangle */
import { Colors } from 'src/shared/components/styles/colors';
import dayjs from 'dayjs';
import { EventInput } from '@fullcalendar/react';
import { AbsenceResponse } from 'src/services/api/response/absenceResponse';
import { EmployeeResponse } from 'src/services/api/response/employeeResponse';
import { UserTypeEnum } from 'src/shared/enums/userType.enum';

interface ICustomExtendedProps {
  person: string;
  approved: boolean;
  answeredDate: string;
  type: number;
  reason: number;
  managerId: string;
  description?: string;
  userComment: string;
  ID: number;
}

export enum AbsenceReasonEnum {
  VAB = 1, // Manager notifieras endast "VAB"
  Illness = 2, // Manager notifieras endast "Sjuk"
  Vacation = 3, // Manager måste approva "Semester"
  ParentalLeave = 4, // Manager måste approva "Föräldraledighet"
  Other = 22, // Manager måste approva "Övrigt"
  BusinessTrip = 22, // Manager notifieras endast "Tjänsteärende"
  DistanceWork = 33, // Manager notifieras endast "Distansarbete"
}

export enum AbsenceTypeEnum {
  Absent = 1,
  Leave = 1,
  BusinessTrip = 2,
  RemoteWork = 3,
}

export const MenuItems = [
  {
    id: 1,
    Name: 'Frånvaro',
  },
  {
    id: 3,
    Name: 'Ledighet',
  },
  {
    id: 2,
    Name: 'Tjänsteärende',
  },
  {
    id: 4,
    Name: 'Distansarbete',
  },
];

export interface ICalendarEvent extends EventInput {
  extendedProps?: ICustomExtendedProps;
}

export const getDateColor = (date: string | Date) => {
  let dateColor = Colors.GRAY_MEDIUM_DARK;
  if (dayjs(date).day() === 6) {
    dateColor = Colors.GRAY_DARK;
  }
  if (dayjs(date).day() === 0) {
    dateColor = Colors.RED;
  }
  return dateColor;
};

export const getAbsenceColor = (absence: AbsenceResponse) => {
  let color = Colors.GRAY_LIGHT;
  if (absence.Approved) {
    color = Colors.RED;
  }
  if (absence.Type === 'Tjänsteärende') {
    color = Colors.YELLOW;
  }
  if (absence.Type === 'Distansarbete') {
    color = Colors.GREEN;
  }
  return color;
};

export const getAbsenceBorderColor = (absence: AbsenceResponse) => {
  let borderColor = 'transparent';
  if (!absence.Approved && absence.AnswerDate) {
    borderColor = Colors.RED;
  }
  if (!absence.Approved && !absence.AnswerDate) {
    borderColor = Colors.GRAY_DARK;
  }
  return borderColor;
};
export const getAbsenceTextColor = (absence: ICalendarEvent) => {
  let textColor = Colors.WHITE;
  if (
    (!absence?.extendedProps?.approved && absence?.extendedProps?.answeredDate) ||
    (!absence?.extendedProps?.approved && !absence.extendedProps?.answeredDate)
  ) {
    textColor = Colors.GRAY_DARK;
  }
  return textColor;
};

export const getAbsenceText = (absence: ICalendarEvent, user: EmployeeResponse | undefined, t: (n: string) => string) => {
  // Skriv ut "Frånvaro" som default
  const resourceID = absence?._def?.resourceIds && absence?._def?.resourceIds[0];
  let absenceText = t('pageAbsence');
  if (resourceID === user?.GUID || absence?.extendedProps?.managerId === user?.GUID || user?.UserType === UserTypeEnum.Admin) {
    // Jag själv och managern får se detaljerad info för olika typer av frånvaro
    if (absence?.extendedProps?.type !== 2 && absence?.extendedProps?.reason === 22) {
      absenceText = t('options.leave.other');
    }
    if (absence?.extendedProps?.type === 2) {
      absenceText = t('pageService');
    }
    if (absence?.extendedProps?.reason === 33) {
      absenceText = t('pageDistanceWork');
    }
    if (absence?.extendedProps?.reason === 1) {
      absenceText = t('options.absence.vab');
    }
    if (absence?.extendedProps?.reason === 2) {
      absenceText = t('options.absence.illness');
    }
    if (absence?.extendedProps?.reason === 3) {
      absenceText = t('options.leave.vacation');
    }
    if (absence?.extendedProps?.reason === 4) {
      absenceText = t('options.leave.parentalLeave');
    }
  } else if (absence?.extendedProps?.type === 2) {
    // Övriga får se text Tjänsteärende
    absenceText = t('pageService');
  } else if (absence?.extendedProps?.type === 3) {
    // Övriga får se text Distansarbete
    absenceText = t('pageDistanceWork');
  }
  return absenceText;
};

export const getAbsenceStatus = (absence: ICalendarEvent, user: EmployeeResponse | undefined, t: (n: string) => string) => {
  const absenceUserID = absence.UserID;
  const absenceManagerID = absence.ManagerUserID;
  const isAdmin = user?.UserType === UserTypeEnum.Admin;

  let absenceStatus = '';

  // Tjänsteärende, distansarbete, sjuk och VAB som inte behöver godkännande ska inte visa status
  switch (absence?.extendedProps?.type) {
    case AbsenceTypeEnum.BusinessTrip:
      return absenceStatus;
    default:
      break;
  }
  switch (absence?.extendedProps?.reason) {
    case AbsenceReasonEnum.DistanceWork:
    case AbsenceReasonEnum.VAB:
    case AbsenceReasonEnum.Illness:
      return absenceStatus;
    default:
      break;
  }
  // Om det är användarens egna frånvaro, manager eller admin ska status visas för ledigheter
  if (absenceUserID === user?.GUID || absenceManagerID === user?.GUID || isAdmin) {
    switch (true) {
      case absence?.extendedProps?.approved:
        absenceStatus = `(${t('calendar:approved')})`;
        break;
      case absence?.extendedProps?.answeredDate === null && !absence?.extendedProps?.approved:
        absenceStatus = `(${t('calendar:unanswered')})`;
        break;
      case absence?.extendedProps?.answeredDate !== null && !absence?.extendedProps?.approved:
        absenceStatus = `(${t('calendar:disapproved')})`;
        break;
      default:
        break;
    }
  }
  return absenceStatus;
};

export const getAbsenceTypeIdToFilterMenuItems = (absence: AbsenceResponse) => {
  let id = 1;
  if (absence.Type === 'Tjänsteärende') {
    id = 2;
  } else if (absence.Reason === 'Distansarbete') {
    id = 4;
  } else if (absence.Type === 'Sjuk eller ledig' && (absence.Reason === 'VAB' || absence.Reason === 'Sjuk')) {
    id = 1;
  } else {
    id = 3;
  }
  return id;
};

export const getAbsenceTypeID = (absence: AbsenceResponse) => {
  let absenceTypeID = 1;
  if (absence.Type === 'Tjänsteärende') {
    absenceTypeID = 2;
  } else if (absence.Reason === 'Distansarbete') {
    absenceTypeID = 3;
  } else {
    absenceTypeID = 1;
  }
  return absenceTypeID;
};
export const getAbsenceReasonID = (absence: AbsenceResponse) => {
  let absenceReasonID = 1;
  if (absence.Type === 'Tjänsteärende') {
    absenceReasonID = 22;
  } else if (absence.Type === 'Distansarbete') {
    absenceReasonID = 33;
  } else if (absence.Reason === 'VAB') {
    absenceReasonID = 1;
  } else if (absence.Reason === 'Sjuk') {
    absenceReasonID = 2;
  } else if (absence.Reason === 'Semester') {
    absenceReasonID = 3;
  } else if (absence.Reason === 'Föräldraledighet') {
    absenceReasonID = 4;
  } else if (absence.Type !== 'Tjänsteärende' && absence.Reason === 'Övrigt') {
    absenceReasonID = 22;
  }
  return absenceReasonID;
};

export const selectReasonAbsence = (t: (n: string) => string) => [
  {
    value: AbsenceReasonEnum.VAB,
    label: t('calendar:options.absence.vab'),
  },
  {
    value: AbsenceReasonEnum.Illness,
    label: t('calendar:options.absence.illness'),
  },
];
export const selectReasonLeave = (t: (n: string) => string) => [
  {
    value: AbsenceReasonEnum.Vacation,
    label: t('calendar:options.leave.vacation'),
  },
  {
    value: AbsenceReasonEnum.ParentalLeave,
    label: t('calendar:options.leave.parentalLeave'),
  },
  {
    value: AbsenceReasonEnum.Other,
    label: t('calendar:options.leave.other'),
  },
];

export const getTitleForFromDatepicker = (t: (n: string) => string, absType: number, resType: number) => {
  if (absType === AbsenceTypeEnum.BusinessTrip) {
    return t('content.onBusinesTripFrom');
  }
  if (absType === AbsenceTypeEnum.RemoteWork) {
    return t('content.distanceWorkFrom');
  }
  if (absType === AbsenceTypeEnum.Absent && (resType === AbsenceReasonEnum.VAB || resType === AbsenceReasonEnum.Illness)) {
    return t('content.absenceStartDay');
  }
  return t('content.applyLeaveFrom');
};

export const getTitleForAnotherEmployeeDatePicker = (t: (n: string) => string, absType: number, resType: number) => {
  if (absType === AbsenceTypeEnum.BusinessTrip) {
    return t('content.contentAdmin.onBusinesTripFrom');
  }
  if (absType === AbsenceTypeEnum.RemoteWork) {
    return t('content.contentAdmin.distanceWorkFrom');
  }
  if (absType === AbsenceTypeEnum.Absent && (resType === AbsenceReasonEnum.VAB || resType === AbsenceReasonEnum.Illness)) {
    return t('content.contentAdmin.absenceStartDay');
  }
  return t('content.contentAdmin.applyLeaveFrom');
};

export const getAbsenceReasonTitle = (absence: AbsenceResponse, user: EmployeeResponse | undefined, t: (n: string) => string) => {
  const absenceUserID = absence.UserID;
  const absenceManagerID = absence.ManagerUserID;
  const isAdmin = user?.UserType === UserTypeEnum.Admin;
  const absenceType = getAbsenceTypeID(absence);
  const absenceReason = getAbsenceReasonID(absence);

  // Skriv ut "Frånvaro" som default
  let absenceText = t('calendar:pageAbsence');
  if (absenceUserID === user?.GUID || absenceManagerID === user?.GUID || isAdmin) {
    // Jag själv och managern får se detaljerad info för olika typer av frånvaro
    if (absenceType !== AbsenceTypeEnum.BusinessTrip && absenceReason === AbsenceReasonEnum.Other) {
      absenceText = t('calendar:options.leave.other');
    }
    if (absenceType === AbsenceTypeEnum.BusinessTrip) {
      absenceText = t('calendar:pageService');
    }
    if (absenceReason === AbsenceReasonEnum.DistanceWork) {
      absenceText = t('calendar:pageDistanceWork');
    }
    if (absenceReason === AbsenceReasonEnum.VAB) {
      absenceText = t('calendar:options.absence.vab');
    }
    if (absenceReason === AbsenceReasonEnum.Illness) {
      absenceText = t('calendar:options.absence.illness');
    }
    if (absenceReason === AbsenceReasonEnum.Vacation) {
      absenceText = t('calendar:options.leave.vacation');
    }
    if (absenceReason === AbsenceReasonEnum.ParentalLeave) {
      absenceText = t('calendar:options.leave.parentalLeave');
    }
  } else if (absenceType === AbsenceTypeEnum.BusinessTrip) {
    // Övriga får se text Tjänsteärende
    absenceText = t('calendar:pageService');
  } else if (absenceType === AbsenceTypeEnum.RemoteWork) {
    // Övriga får se text Distansarbete
    absenceText = t('calendar:pageDistanceWork');
  }
  return absenceText;
};

export const absenceResponseNeedApproval = (absence: AbsenceResponse) => {
  const absenceType = getAbsenceTypeID(absence);
  const absenceReason = getAbsenceReasonID(absence);
  let needApproval = false;

  if (absenceType !== AbsenceTypeEnum.BusinessTrip && absenceReason === AbsenceReasonEnum.Other) {
    needApproval = true;
  }
  if (absenceReason === AbsenceReasonEnum.Vacation) {
    needApproval = true;
  }
  if (absenceReason === AbsenceReasonEnum.ParentalLeave) {
    needApproval = true;
  }
  return needApproval;
};

export const calendarEventNeedApproval = (absence: ICalendarEvent) => {
  let needApproval = false;
  if (absence?.extendedProps?.type !== AbsenceTypeEnum.BusinessTrip && absence?.extendedProps?.reason === AbsenceReasonEnum.Other) {
    needApproval = true;
  }
  if (absence?.extendedProps?.reason === AbsenceReasonEnum.Vacation) {
    needApproval = true;
  }
  if (absence?.extendedProps?.reason === AbsenceReasonEnum.ParentalLeave) {
    needApproval = true;
  }
  return needApproval;
};
