import { FormHelperText, InputAdornment, TextField, Typography } from '@mui/material';
import { Controller, useFormContext, useFormState } from 'react-hook-form';
import { Error } from '@mui/icons-material';

export const ControlledTextInput = (props: any) => {
  const { control } = useFormContext();
  const { errors } = useFormState();

  const { name, label, multiline, size, sx, placeholder, heading, disabled } = props;

  const componentId = `textfield-${name}-${Date.now().toString(36) + Math.random().toString(36).substring(2)}`;

  let isError = false;
  let errorMessage: string | any = '';
  if (errors && Object.prototype.hasOwnProperty.call(errors, name)) {
    isError = true;
    errorMessage = errors[name]?.message;
  }

  return (
    <Controller
      name={name}
      control={control}
      render={({ field }) => (
        <>
          <Typography variant='subtitle2' sx={sx}>
            {heading}
          </Typography>
          <TextField
            {...field}
            {...props}
            error={isError}
            label={label}
            // defaultValue={inputValue && inputValue}
            id={componentId}
            sx={{ mt: 1, pt: 0 }}
            type='outlined'
            size={size}
            multiline={multiline}
            maxRows={3}
            placeholder={placeholder}
            fullWidth
            margin='dense'
            InputProps={{
              disabled: disabled ? true : undefined,
              'aria-invalid': isError,
              endAdornment: isError ? (
                <InputAdornment position='end'>
                  <Error sx={{ color: 'error.main' }} />
                </InputAdornment>
              ) : (
                <></>
              ),
            }}
          />
          <FormHelperText sx={{ color: 'error.main' }}>{isError ? errorMessage : ''}</FormHelperText>
        </>
      )}
    />
  );
};
ControlledTextInput.defaultProps = {
  label: '',
  heading: '',
  text: 'text',
  size: 'small',
  multiline: true,
};
