export const AllValidTypes = () => {
  return [
    'image/',
    'image/jpg',
    'image/jpeg',
    'image/png',
    'image/gif',
    'image/tiff',
    'text/plain',
    'application/pdf',
    'application/mspowerpoint',
    'application/word',
    'application/msword',
    'application/xlsx',
    'application/vnd.ms-excel',
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    'application/vnd.openxmlformats-officedocument.presentationml.presentation',
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  ];
};

export const ImageValidTypes = () => {
  return ['image/', 'image/jpg', 'image/jpeg', 'image/png', 'image/gif'];
};

export const CheckIfIsImage = (type: string) => {
  if (type === 'image/jpeg' || type === 'image/png' || type === 'image/jpg') {
    return true;
  }
  return false;
};

export const GetFileExtension = (filename: string) => {
  return /[.]/.exec(filename) ? /[^.]+$/.exec(filename?.toUpperCase()) : undefined;
};

export const getDocumentName = (url: string) => {
  let docTitle = 'Document';
  docTitle = url.substring(url.lastIndexOf('/') + 1);
  if (docTitle.length > 50) {
    return `${docTitle.substring(0, 8)}...${docTitle.substring(docTitle.length - 10, docTitle.length)}`;
  }
  return docTitle;
};
