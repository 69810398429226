import { useQueryClient } from '@tanstack/react-query';
import dayjs from 'dayjs';
import { calendarKeys } from 'src/modules/calendar/hooks/calendarKeys';
import { getAbsence } from 'src/modules/calendar/hooks/queries/useFetchAbsence';
import { progressKeys } from 'src/modules/progress/hooks/progressKeys';
import { useGlobals } from 'src/services/contexts/appContext';
import { getProgress } from 'src/modules/progress/hooks/queries/useFetchProgress';
import { employeeKeys } from 'src/modules/employee/hooks/employeeKeys';
import { getInfinityEmployees } from 'src/modules/employee/hooks/queries/useFetchEmployee';
import { careerKeys } from 'src/modules/career/hooks/careerKeys';
import { CareerOptions } from 'src/services/api/options/careerOptions';
import { getCareerItems } from 'src/modules/career/hooks/queries/useFetchCareer';

export type PrefetchFn<T> = () => Promise<void>;

export type PrefetchRoutes<T> = {
  [K in keyof T]: PrefetchFn<T[K]>;
};

export type Routes = {
  calendar: void;
  progress: void;
  employee: void;
  career: void;
};

export const usePrefetchRoutes = <T extends Record<string, any>>(): PrefetchRoutes<T> => {
  const queryClient = useQueryClient();
  const { loggedInUser } = useGlobals();

  const prefetchCalendar: PrefetchFn<T['calendar']> = async () => {
    await queryClient.prefetchQuery({
      queryKey: calendarKeys.list({
        From: dayjs().startOf('week').format('YYYY-MM-DD'),
        To: dayjs().endOf('week').format('YYYY-MM-DD'),
        DepartmentGUID: '',
      }),
      queryFn: () =>
        getAbsence({
          From: dayjs().startOf('week').format('YYYY-MM-DD'),
          To: dayjs().endOf('week').format('YYYY-MM-DD'),
          DepartmentGUID: '',
        }),
    });
  };
  const prefetchProgress: PrefetchFn<T['progress']> = async () => {
    await queryClient.prefetchQuery({
      queryKey: progressKeys.byEmployee(loggedInUser?.GUID ?? ''),
      queryFn: () => loggedInUser && getProgress(loggedInUser?.GUID),
    });
  };
  const prefetchEmployee: PrefetchFn<T['employee']> = async () => {
    await queryClient.prefetchInfiniteQuery({
      queryKey: employeeKeys.infinityList({
        DepartmentGUID: '',
        Filter: '',
      }),
      queryFn: () =>
        getInfinityEmployees({
          DepartmentGUID: '',
          Filter: '',
          PageSize: 10,
          PageIndex: 0,
        }),
    });
  };

  const prefetchCareer: PrefetchFn<T['career']> = async () => {
    const reqOptions = new CareerOptions();
    await queryClient.prefetchQuery({
      queryKey: careerKeys.list(reqOptions, ''),
      queryFn: () => getCareerItems(reqOptions, ''),
    });
  };

  return {
    calendar: prefetchCalendar,
    progress: prefetchProgress,
    employee: prefetchEmployee,
    career: prefetchCareer,
  } as unknown as PrefetchRoutes<T>;
};
