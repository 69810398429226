import { InfiniteData, useMutation, useQueryClient } from '@tanstack/react-query';
import { newsKeys } from 'src/modules/home/hooks/newsKeys';
import { InfinityList } from 'src/services/api/helpers/infinityList';
import { CommentDTO } from 'src/services/api/models';
import { Comment } from 'src/services/api/response/comment';
import { NewsAPI } from 'src/services/api/newsApi';
import { NewsResponse } from 'src/services/api/response/newsResponse';

const newsApi = new NewsAPI();

interface AddCommentProps {
  GUID: string;
  Content: string;
}

const addComment = async (props: CommentDTO) => {
  const res = await newsApi.AddComment(props.GUID, props);
  return res;
};

const deleteComment = async ({ guid, comment }: { guid: string; comment: string }) => {
  await newsApi.RemoveComment(guid, comment);
};

export const useDeleteComment = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: deleteComment,
    // onMutate: async ({ guid }) => {
    //   await queryClient.cancelQueries(newsKeys.byId(guid));

    //   // Snapshot the previous value
    //   const previousTodo = queryClient.getQueryData<NewsResponse>(
    //     newsKeys.byId(guid),
    //   );

    //   // Optimistically update to the new value
    //   queryClient.setQueryData<NewsResponse>(newsKeys.byId(guid), (news) => {
    //     return news;
    //   });

    //   // Return a context with the previous and new todo
    //   return previousTodo;
    // },
    onSettled: () => {
      return queryClient.invalidateQueries({ queryKey: newsKeys.all });
    },
  });
};

export const useAddComment = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: addComment,
    onMutate: (comment) => {
      queryClient.cancelQueries(newsKeys.list());
      queryClient.setQueriesData<InfiniteData<InfinityList<NewsResponse>>>(['news', 'list'], (prev) => {
        const containsNews = prev?.pages.some((page) => page.result.some((news) => news.GUID === comment.GUID));
        if (prev && containsNews) {
          return {
            ...prev,
            pages: prev.pages.map((page) => {
              return {
                ...page,
                result: page.result.map((news) => {
                  if (news.GUID === comment.GUID) {
                    const newComment = comment as Comment;
                    return {
                      ...news,
                      Comments: [...news.Comments, newComment],
                    };
                  }
                  return news;
                }),
              };
            }),
          };
        }
        return undefined;
      });
    },
    onSettled: () => {
      return queryClient.invalidateQueries({ queryKey: newsKeys.all });
    },
  });
};
