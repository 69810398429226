import React, { DragEvent, useState, useCallback, useEffect } from 'react';
import { Button, CardMedia, Grid, IconButton, Box, Paper, Typography, Tooltip } from '@mui/material';
import { Close, AddRounded } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';
import { RootStateType } from 'src/services/redux/reducers';
import { ImageValidTypes } from 'src/services/helpers/validTypes';
import getId from 'src/services/helpers/idGenerator';
import ConsoleHelper from 'src/services/helpers/consoleHelper';
import { Colors } from 'src/shared/components/styles/colors';
import ConfirmDialogModal from '../atoms/confirmDialog';

type ImageDropZoneProps = {
  onFileChange: (files: File | undefined) => void;
  initialImage?: string | null;
  onPressDeleteInitialImage?: (image: string) => void;
  deleteInitialImageDisabled?: boolean;
};

export interface FileExtended extends File {
  originalFilename?: string | undefined;
  id?: string | number | undefined;
  mimeType?: string | undefined;
}
const ImageDropZone = (props: ImageDropZoneProps) => {
  const { onFileChange, initialImage, onPressDeleteInitialImage, deleteInitialImageDisabled } = props;
  const { t } = useTranslation(['common, editor']);

  const [inputId] = useState(() => getId('fileInput-'));

  const { user } = useSelector((state: RootStateType) => state.oidc);
  const [showDeleteDialog, setShowDeleteDialog] = useState<boolean>(false);
  const [itemToBeDeleted, setItemTobeDeleted] = useState<File | string | undefined>();
  const [validCurrentImage, setValidCurrentImage] = useState<string | undefined | null>(initialImage);
  const [validFile, setValidFile] = useState<FileExtended | undefined>();
  const [invalidFiles, setInvalidFile] = useState<FileExtended>(); // if we want to show a file list with invalid files..

  // Sätter initalt state med befintlig bild
  useEffect(() => {
    setValidCurrentImage(initialImage);
  }, [initialImage, setValidCurrentImage]);

  // Sätter state i parent om bild-array ändras
  useEffect(() => {
    onFileChange(validFile);
  }, [onFileChange, validFile]);

  // Validerar fil
  const validateFile = (newFile: FileExtended) => {
    if (ImageValidTypes().indexOf(newFile.type) === -1) {
      return false;
    }
    return true;
  };

  // Validerar & sätter states för bild
  const validateAndAddFile = (file: File) => {
    if (validateFile(file)) {
      // add local image so we can display the name of file
      setValidFile(file);
    } else {
      setInvalidFile(file);
      toast.warn(`${t('editor:InvalidFile')}: ${file.name}`);
    }
  };

  // Metoder för drag & drop
  const fileDrop = (e: DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    const { files } = e.dataTransfer;
    validateAndAddFile(files[0]);
  };
  const dragOver = (e: DragEvent<HTMLDivElement>) => {
    e.preventDefault();
  };
  const dragEnter = (e: DragEvent<HTMLDivElement>) => {
    e.preventDefault();
  };
  const dragLeave = (e: DragEvent<HTMLDivElement>) => {
    e.preventDefault();
  };

  // On change i input elemement
  const changeHandler = async (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    const { files } = e.target;
    if (files) validateAndAddFile(files[0]);
    e.target.value = ''; // Hack to reset input element (if you select the same file  after removing it wont work otherwise)
  };

  // Onclick radera fil
  const showDeleteModal = (image: FileExtended | string) => {
    setItemTobeDeleted(image);
    setShowDeleteDialog(true);
  };

  // Raderar lokal fil (ej uppladdad än)
  const removeFile = () => {
    setValidFile(undefined);
  };

  const deleteNewImage = async () => {
    if (itemToBeDeleted && typeof itemToBeDeleted === 'string') {
      onPressDeleteInitialImage?.(itemToBeDeleted);
      setValidCurrentImage(null);
    } else {
      removeFile();
    }
    setShowDeleteDialog(false);
  };

  // Anropa metoder efter confirm delete
  const deleteFile = async () => {
    removeFile();
    setShowDeleteDialog(false);
  };

  const formatSize = (a: number, b = 2) => {
    if (a === 0) return '0 Bytes';
    const c = b < 0 ? 0 : b;
    const d = Math.floor(Math.log(a) / Math.log(1024));
    return `${parseFloat((a / 1024 ** d).toFixed(c))} ${['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'][d]}`;
  };

  // Renderar bild
  const renderInitialImage = useCallback(() => {
    return (
      <Box>
        {validCurrentImage && (
          <>
            <CardMedia
              sx={{
                maxHeight: '150px',
                maxWidth: '100%',
                '&.MuiCardMedia-img': {
                  width: 'auto',
                  objectFit: 'contain',
                },
              }}
              image={
                `${validCurrentImage}?access_token=${user?.access_token}` // TODO: Fixa URL för att visa befitnlig fil från API
              }
              onError={() => ConsoleHelper.log('error')}
              component='img'
            />
            <Box
              sx={{
                width: '100%',
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <IconButton size='small' sx={{ mr: -2 }} onClick={() => showDeleteModal(validCurrentImage)} disabled={deleteInitialImageDisabled}>
                <Tooltip title={t('common:delete')}>
                  <Close />
                </Tooltip>
              </IconButton>
            </Box>
          </>
        )}
      </Box>
    );
  }, [validCurrentImage, user?.access_token, deleteInitialImageDisabled, t]);

  const renderImage = useCallback(() => {
    return (
      <Box>
        {validFile && (
          <>
            <CardMedia
              sx={{
                maxHeight: '150px',
                maxWidth: '100%',
                '&.MuiCardMedia-img': {
                  width: 'auto',
                  objectFit: 'contain',
                },
              }}
              image={validFile.originalFilename ? `${validFile}?access_token=${user?.access_token}` : window.URL.createObjectURL(validFile)}
              onError={() => ConsoleHelper.log('error')}
              title={validFile.name}
              component='img'
            />
            <Box
              sx={{
                width: '100%',
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <Typography component='span' variant='caption' sx={{ overflow: 'hidden', textOverflow: 'ellipsis' }}>
                {validFile.name}
              </Typography>
              <Typography component='span' variant='caption' sx={{ pl: 1, alignSelf: 'center' }}>
                ({formatSize(validFile.size)})
              </Typography>

              <IconButton size='small' sx={{ mr: -2 }} onClick={() => showDeleteModal(validFile)}>
                <Tooltip title={t('common:delete')}>
                  <Close />
                </Tooltip>
              </IconButton>
            </Box>
          </>
        )}
      </Box>
    );
  }, [validFile, user?.access_token, t]);
  return (
    <>
      {validCurrentImage ? (
        <Paper
          sx={{
            p: 1,
            width: '100%',
          }}
          elevation={0}
          onDragOver={dragOver}
          onDragEnter={dragEnter}
          onDragLeave={dragLeave}
          onDrop={fileDrop}
        >
          <Grid
            container
            sx={{
              minHeight: 150,
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'normal',
            }}
          >
            {!validCurrentImage ? (
              <Grid
                item
                xs={12}
                md={4}
                sx={{
                  justifyContent: 'center',
                  display: 'flex',
                  flexDirection: 'column',
                }}
              >
                <label htmlFor={inputId}>
                  <input
                    style={{ display: 'none' }}
                    id={inputId}
                    name={inputId}
                    type='file'
                    accept={ImageValidTypes().toString()}
                    onChange={changeHandler}
                  />
                  <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                    <Typography marginBottom={0.5} variant='subtitle2'>
                      Bild
                    </Typography>
                    <Button
                      variant='outlined'
                      component='span'
                      disabled={validFile !== undefined}
                      sx={{
                        width: 100,
                        height: 100,
                        color: Colors.GRAY_MEDIUM,
                        border: '2px dashed #CCCCCC',
                        borderRadius: 1,
                        '&:hover': {
                          color: Colors.ACCENT_DARK,
                          border: '2px dashed #CCCCCC',
                        },
                      }}
                    >
                      <Tooltip title={t('common:addImage')}>
                        <AddRounded fontSize='large' />
                      </Tooltip>
                    </Button>
                  </Box>
                </label>
              </Grid>
            ) : (
              <Grid item xs={12} md={8} sx={{ p: 1 }}>
                {renderInitialImage()}
              </Grid>
            )}
          </Grid>
          <ConfirmDialogModal
            open={showDeleteDialog}
            dialogTitle={t('common:confirm')}
            dialogText={t('common:confirmDelete')}
            handleClose={() => setShowDeleteDialog(false)}
            confirmButtonClick={() => deleteNewImage()}
            confirmTextButton={t('common:ok')}
            cancelTextButton={t('common:cancel')}
          />
        </Paper>
      ) : (
        <Paper
          sx={{
            p: 1,
            width: '100%',
          }}
          elevation={0}
          onDragOver={dragOver}
          onDragEnter={dragEnter}
          onDragLeave={dragLeave}
          onDrop={fileDrop}
        >
          <Grid
            container
            sx={{
              minHeight: 150,
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'normal',
            }}
          >
            {!validFile ? (
              <Grid
                item
                xs={12}
                md={4}
                sx={{
                  justifyContent: 'center',
                  display: 'flex',
                  flexDirection: 'column',
                }}
              >
                <label htmlFor={inputId}>
                  <input
                    style={{ display: 'none' }}
                    id={inputId}
                    name={inputId}
                    type='file'
                    accept={ImageValidTypes().toString()}
                    onChange={changeHandler}
                  />
                  <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                    <Typography marginBottom={0.5} variant='subtitle2'>
                      Bild
                    </Typography>
                    <Button
                      variant='outlined'
                      component='span'
                      disabled={validFile !== undefined}
                      sx={{
                        width: 100,
                        height: 100,
                        color: Colors.GRAY_MEDIUM,
                        border: '2px dashed #CCCCCC',
                        borderRadius: 1,
                        '&:hover': {
                          color: Colors.ACCENT_DARK,
                          border: '2px dashed #CCCCCC',
                        },
                      }}
                    >
                      <Tooltip title={t('common:addImage')}>
                        <AddRounded fontSize='large' />
                      </Tooltip>
                    </Button>
                  </Box>
                </label>
              </Grid>
            ) : (
              <Grid item xs={12} md={8} sx={{ p: 1 }}>
                {renderImage()}
              </Grid>
            )}
          </Grid>
          <ConfirmDialogModal
            open={showDeleteDialog}
            dialogTitle={t('common:confirm')}
            dialogText={t('common:confirmDelete')}
            handleClose={() => setShowDeleteDialog(false)}
            confirmButtonClick={() => deleteNewImage()}
            confirmTextButton={t('common:ok')}
            cancelTextButton={t('common:cancel')}
          />
        </Paper>
      )}
    </>
  );
};

ImageDropZone.defaultProps = {
  initialImage: undefined,
  onPressDeleteInitialImage: '',
  deleteInitialImageDisabled: false,
};

export default React.memo(ImageDropZone);
