import { NewsAPI } from 'src/services/api/newsApi';
import { NewsCategory } from 'src/services/api/response/newsCategory';
import { useQuery } from '@tanstack/react-query';
import { newsKeys } from 'src/modules/home/hooks/newsKeys';

const getNewsCategories = async (onHomePage: boolean) => {
  const categories = new NewsAPI();
  const res: NewsCategory[] = await categories.GetNewsCategories();
  if (onHomePage) {
    const newsCategory: NewsCategory[] = [{ Id: 0, Name: 'Alla' }];

    if (res && res.length > 0) {
      res.forEach((element: any) => {
        newsCategory.push(element);
      });
    }

    return newsCategory;
  }
  res.sort((a: { Name: string }, b: { Name: string }) => {
    return a.Name.localeCompare(b.Name);
  });

  return res;
};

export const useFetchNewsCategories = (onHomePage: boolean) => {
  return useQuery({
    queryKey: newsKeys.categories,
    queryFn: () => getNewsCategories(onHomePage),
  });
};
