import { Box, LinearProgress, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { Editor as TinyMCE } from '@tinymce/tinymce-react';

type ControlledTextEditorProps = {
  name: string;
  initCallback: () => void;
  heading: string;
};

const ControlledTextEditor = (props: ControlledTextEditorProps) => {
  const { name, initCallback, heading } = props;

  const [loading, setLoading] = useState<boolean>(true);

  const { control } = useFormContext();

  // Work around for inserting links
  useEffect(() => {
    const handler = (e: any) => {
      if (e.target.closest('.tox-tinymce-aux, .moxman-window, .tam-assetmanager-root') !== null) {
        e.stopImmediatePropagation();
      }
    };
    document.addEventListener('focusin', handler);
    return () => document.removeEventListener('focusin', handler);
  }, []);

  return (
    <Box mb={2}>
      <Controller
        name={name}
        control={control}
        render={({ field: { onChange, value } }) => {
          return (
            <>
              <Typography variant='subtitle2' marginTop={1.5} marginBottom={0.5}>
                {heading}
              </Typography>
              <Box
                sx={{
                  width: '100%',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  height: '20rem',

                  '& .tox-tinymce': {
                    borderRadius: 1,
                    border: '1px solid #CCC',
                  },
                  '& textarea': {
                    display: 'none',
                  },
                }}
              >
                {loading && <LinearProgress />}
                <TinyMCE
                  tinymceScriptSrc='/tinymce/tinymce.min.js'
                  value={value}
                  init={{
                    height: '20rem',
                    borderRadius: '4px',
                    statusbar: false,
                    menubar: false,
                    link_default_target: '_blank',
                    relative_urls: false, // För att editorn inte ska omformatera länkar inom appen
                    convert_urls: false, // För att editorn inte ska omformatera länkar inom appen
                    remove_script_host: false, // För att editorn inte ska omformatera länkar inom appen
                    noneditable_noneditable_class: 'is-locked',
                    width: '100%',
                    plugins: 'autolink fullscreen link hr advlist lists wordcount textpattern noneditable help charmap emoticons',
                    toolbar:
                      'bold italic underline strikethrough | alignleft aligncenter alignright alignjustify |  numlist bullist | link forecolor backcolor removeformat emoticons | undo redo fullscreen',
                    content_style: `
                    .is-locked,
                    .mceNonEditable {
                        background-color: #D6F0FF;
                        padding: 1px 0;
                        color: #44719B;
                        font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
                        font-size: 0.9375em;
                    }
                `,
                  }}
                  onEditorChange={(content) => {
                    onChange(content);
                  }}
                />
              </Box>
            </>
          );
        }}
      />
    </Box>
  );
};

export default ControlledTextEditor;
