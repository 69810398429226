import { Modal, Box } from '@mui/material';
import { LoadingLinearProgress } from '../components/atoms/loadingLinearProgress';
import ModalHeaderTemplate from './modalHeaderTemplate';

type ModalTemplateProps = {
  title: string;
  subtitle?: JSX.Element;
  content: JSX.Element;
  secondaryAction?: JSX.Element;
  isOpen: boolean;
  modalWidth?: string;
  onClose: () => void;
  isLoading: boolean;
};

const ModalTemplate = (props: ModalTemplateProps) => {
  const { title, subtitle, content, secondaryAction, isOpen, onClose, modalWidth, isLoading } = props;
  return (
    <Modal open={isOpen} onClose={onClose} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
      <Box
        id='modalScroll'
        sx={{
          width: { xs: 'calc(100vw - 32px)', lg: modalWidth },
          bgcolor: 'background.paper',
          borderRadius: 1,
          boxShadow: 24,
          p: { xs: 1, md: 4 },
          m: 0,
          maxHeight: 'calc(100vh - 32px)',
          overflow: 'auto',
        }}
      >
        <ModalHeaderTemplate title={title} subtitle={subtitle} onClose={onClose} />
        <LoadingLinearProgress isLoading={isLoading} />
        <>{content}</>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'flex-end',
          }}
        >
          {secondaryAction}
        </Box>
      </Box>
    </Modal>
  );
};

ModalTemplate.defaultProps = {
  secondaryAction: undefined,
  modalWidth: '50%',
  subtitle: undefined,
};

export default ModalTemplate;
