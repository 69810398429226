import { ListItemButton, ListItemText } from '@mui/material';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { NewsModal } from 'src/modules/home/components/newsModal';
import { NewsAPI } from 'src/services/api/newsApi';
import { NotificationAPI } from 'src/services/api/notificationAPI';
import { NotificationResponse } from 'src/services/api/response/notificationResponse';
import ConsoleHelper from 'src/services/helpers/consoleHelper';
import { useQueryClient } from '@tanstack/react-query';
import { InformationModal } from './informationModal';
import { ManagerHandleLeaveModal } from './managerHandleLeaveModal';

type NotificationListItemProps = {
  item: NotificationResponse;
};

export const NotificationListItem = (props: NotificationListItemProps) => {
  const { item } = props;
  const { t } = useTranslation();
  const [modalInformationOpen, setModalInformationOpen] = useState(false);
  const [modalToApproveOpen, setModalToApproveOpen] = useState(false);
  const [newsModalOpen, setNewsModalOpen] = useState(false);
  const queryClient = useQueryClient();

  const handleClickItem = async () => {
    if (!item.is_read) {
      const notificationApi = new NotificationAPI();
      try {
        await notificationApi.SetNotificationReadStatus(item?.ID);
        queryClient.invalidateQueries(['notifications']);
      } catch (error) {
        ConsoleHelper.log('Error: Marking a notification as read');
      }
    }
    if (item.type === 'news') {
      // Mark the news as read
      const newsApi = new NewsAPI();
      await newsApi.ReadNews(item.id_for_type_post);
      setNewsModalOpen(true);
    } else if (item.type === 'absence_for_approval') {
      setModalToApproveOpen(true);
    } else {
      setModalInformationOpen(true);
    }
  };

  return (
    <>
      <ListItemButton
        //   component={Link}
        //   to={`/${item.type}/${item.idForType}`}
        onClick={handleClickItem}
        divider
      >
        <ListItemText
          primary={`${t(item?.title ?? 'NewContent')}`}
          secondary={`${item?.message ?? t('NewContent')}`}
          primaryTypographyProps={{
            fontSize: 14,
            fontWeight: item.is_read ? 'normal' : 'bold',
          }}
          secondaryTypographyProps={{ fontSize: 12, color: 'text.primary' }}
        />
      </ListItemButton>
      {modalInformationOpen && (
        <InformationModal modalOpen={modalInformationOpen} setModalOpen={(arg: boolean) => setModalInformationOpen(arg)} notice={item} />
      )}
      {modalToApproveOpen && (
        <ManagerHandleLeaveModal modalOpen={modalToApproveOpen} setModalOpen={(arg: boolean) => setModalToApproveOpen(arg)} notice={item} />
      )}
      {newsModalOpen && (
        <NewsModal modalOpen={newsModalOpen} setModalOpen={(arg: boolean) => setNewsModalOpen(arg)} newsGUID={item.id_for_type_post} />
      )}
    </>
  );
};
