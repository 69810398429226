import { Link, useLocation } from 'react-router-dom';
import * as icons from '@mui/icons-material';
import { List, ListItem, ListItemIcon, Box, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Routes, usePrefetchRoutes } from 'src/services/helpers/prefetch';
import { Colors } from './styles/colors';
import { MenuItems } from './helper/navigationMenuItems';
import { getRoutePath } from './helper/getRoutePath';

type NavigationMenuProps = {
  menuItems: (t: (n: string) => string, prefetch: any) => MenuItems[];
  listItemDirection: 'row' | 'column';
  listDirection: 'row' | 'column';
  justifyContent: 'center' | 'flex-start' | 'flex-end' | 'space-between';
  padding: number;
};

export const NavigationMenu = (props: NavigationMenuProps) => {
  const { menuItems, listItemDirection, justifyContent, padding, listDirection } = props;
  const location = useLocation();
  const currentPath = getRoutePath(location.pathname);
  const { t } = useTranslation();
  const prefetch = usePrefetchRoutes<Routes>();

  return (
    <List
      component='nav'
      sx={{
        '&.Mui-disabled': {
          pointerEvents: 'auto',
        },
        display: 'flex',
        flexDirection: listDirection,
        justifyContent: 'space-between',
        width: 650,
      }}
    >
      {menuItems(t, prefetch).map((item) => {
        const Icon = icons[item.Icon as keyof typeof icons];
        return (
          <ListItem
            sx={{
              p: padding,
              textAlign: 'center',
              display: 'flex',
              justifyContent: `${justifyContent}`,
            }}
            button
            key={item.label}
            component={Link}
            to={item.link}
            onMouseEnter={item.onHover ? item.onHover : undefined}
          >
            <Box display='flex' flexDirection={listItemDirection}>
              <ListItemIcon
                sx={{
                  minWidth: 40,
                  justifyContent: 'center',
                  color: currentPath === item.link ? Colors.ACCENT_DARK : '',
                }}
              >
                <Icon />
              </ListItemIcon>
              <Typography
                variant='subtitle1'
                sx={{
                  color: currentPath === item.link ? Colors.ACCENT_DARK : '',
                }}
              >
                {item.label}
              </Typography>
            </Box>
          </ListItem>
        );
      })}
    </List>
  );
};
