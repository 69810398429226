import { useMutation, useQueryClient } from '@tanstack/react-query';
import { newsKeys } from 'src/modules/home/hooks/newsKeys';
import { NewsAPI } from 'src/services/api/newsApi';

const markAsConfirmed = async (guid: string) => {
  const newsApi = new NewsAPI();
  await newsApi.MarkAsConfirmed(guid);
};

export const useMarkAsReadAndUnderstood = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: markAsConfirmed,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: newsKeys.all });
    },
  });
};
