import { Controller, useFormContext, useFormState } from 'react-hook-form';
import { DesktopDatePicker, MobileDatePicker, LocalizationProvider, MobileTimePicker, DesktopTimePicker } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { useTheme } from '@mui/material/styles';
import { FormLabel, TextField, FormControlLabel, useMediaQuery, Typography, Box } from '@mui/material';
import dayjs from 'dayjs';

type ControlledDateTimePickerProps = {
  name: string;
  label?: string;
  infoContent?: string;
  info?: string;
};

const ControlledDateTimePicker = (props: ControlledDateTimePickerProps) => {
  const { name, label, infoContent, info } = props;
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const { control } = useFormContext();
  const { errors } = useFormState();

  let isError = false;
  let errorMessage: string | any = '';
  if (errors && Object.prototype.hasOwnProperty.call(errors, name)) {
    isError = true;
    errorMessage = errors[name]?.message;
  }

  return (
    <>
      <FormLabel sx={{ pt: 1, color: 'text.primary', display: 'flex', mt: '20px' }}>
        <Typography variant='subtitle2' sx={{ mr: 0.5, mb: 0.5 }}>
          {label}
        </Typography>
      </FormLabel>
      <Controller
        name={name}
        control={control}
        render={({ field }) => (
          <FormControlLabel
            sx={{ width: '100%', ml: 0 }}
            control={
              <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={dayjs.locale()}>
                {isMobile ? (
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'center',
                      width: '100%',
                    }}
                  >
                    <MobileDatePicker
                      inputFormat='YYYY-MM-DD'
                      mask='____-__-__'
                      minDate={dayjs('2017-01-01')}
                      value={field.value}
                      onChange={(e) => field.onChange(e)}
                      renderInput={(params) => <TextField size='small' fullWidth sx={{ flex: 7, mr: 1 }} {...params} />}
                    />
                    <MobileTimePicker
                      value={field.value}
                      onChange={(e) => field.onChange(e)}
                      renderInput={(params) => <TextField size='small' fullWidth sx={{ flex: 5 }} {...params} />}
                    />
                  </Box>
                ) : (
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'center',
                      width: '100%',
                    }}
                  >
                    <DesktopDatePicker
                      inputFormat='YYYY-MM-DD'
                      mask='____-__-__'
                      minDate={dayjs('2017-01-01')}
                      onChange={(e) => field.onChange(e)}
                      value={field.value}
                      renderInput={(params) => (
                        <TextField fullWidth error={isError} helperText={isError ? errorMessage : infoContent} sx={{ flex: 7, mr: 1 }} {...params} />
                      )}
                    />
                    <DesktopTimePicker
                      value={field.value}
                      onChange={(e) => field.onChange(e)}
                      renderInput={(params) => (
                        <TextField
                          size='medium'
                          fullWidth
                          sx={{
                            flex: 5,
                          }}
                          {...params}
                        />
                      )}
                    />
                  </Box>
                )}
              </LocalizationProvider>
            }
            key={label}
            label=''
          />
        )}
      />
      <Typography sx={{ color: theme.palette.grey[500], fontStyle: 'italic' }} variant='subtitle1'>
        {info}
      </Typography>
    </>
  );
};
ControlledDateTimePicker.defaultProps = {
  label: undefined,
  infoContent: undefined,
  info: undefined,
};

export default ControlledDateTimePicker;
