import { useMutation, useQueryClient } from '@tanstack/react-query';
import { newsKeys } from 'src/modules/home/hooks/newsKeys';
import { NewsAPI } from 'src/services/api/newsApi';

// API CALLS
const newsApi = new NewsAPI();

const removeParticipant = async (newsItemGuid: string) => {
  await newsApi.RemoveParticipant(newsItemGuid);
};

const addParticipant = async ({ newsItemGuid, nrOfUsers = '0' }: { newsItemGuid: string; nrOfUsers: string }) => {
  await newsApi.AddParticipant(newsItemGuid, nrOfUsers);
};

// MUTATIONS

export const useRemoveParticipant = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: removeParticipant,
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: newsKeys.all });
    },
  });
};

export const useAddParticipant = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: addParticipant,
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: newsKeys.all });
    },
  });
};
