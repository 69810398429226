import { Favorite } from '@mui/icons-material';
import { Box, Typography } from '@mui/material';
import { Colors } from './colors';

type Props = {
  likes: number;
};

function LikeLogo(props: Props): any {
  const { likes } = props;
  return (
    <Box
      sx={{
        display: 'flex',
        gap: '5px',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          backgroundColor: Colors.ACCENT_LIGHT,
          borderRadius: '50%',
          height: '20px',
          width: '20px',
          alignItems: 'center',
        }}
      >
        <Favorite sx={{ color: 'white', width: '60%', marginX: 'auto' }} />
      </Box>
      <Typography variant='subtitle1' color={Colors.GRAY_DARK}>
        {likes}
      </Typography>
    </Box>
  );
}

export default LikeLogo;
