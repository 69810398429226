import { Box, Button, Typography } from '@mui/material';
import DoneAllIcon from '@mui/icons-material/DoneAll';
import { useTranslation } from 'react-i18next';
import { NotificationAPI } from 'src/services/api/notificationAPI';
import { useGlobals } from 'src/services/contexts/appContext';
import ConsoleHelper from 'src/services/helpers/consoleHelper';
import { useEffect, useState } from 'react';
import { Colors } from 'src/shared/components/styles/colors';
import { NotificationResponse } from 'src/services/api/response/notificationResponse';
import { useQueryClient } from '@tanstack/react-query';
import { NotificationListItem } from './notificationListItem';

export const NotificationList = () => {
  const queryClient = useQueryClient();
  const { unreadNotifications, notifications } = useGlobals();
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [showAll, setShowAll] = useState(true);
  const [filteredNotifications, setFilteredNotifications] = useState<NotificationResponse[] | undefined>(undefined);

  const markAllNotificationsAsRead = async () => {
    const API = new NotificationAPI();
    setLoading(true);
    try {
      await API.SetAllNotificationReadStatus();
      queryClient.invalidateQueries(['notifications']);
      setLoading(false);
    } catch (err) {
      ConsoleHelper.log('Error: Marking all notifications as read');
      setLoading(false);
    }
  };

  useEffect(() => {
    if (showAll) {
      setFilteredNotifications(notifications);
    } else {
      setFilteredNotifications(unreadNotifications);
    }
  }, [notifications, showAll, unreadNotifications]);

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          mt: 1,
          mx: 2,
        }}
      >
        <Typography variant='h3'>{t('notifications')}</Typography>
      </Box>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          mt: 1,
          mx: 2,
        }}
      >
        <Box sx={{ display: 'flex' }}>
          <Button disabled={loading} onClick={() => setShowAll(true)} sx={{ backgroundColor: showAll ? Colors.BACKGROUND : null }}>
            {t('all')}
          </Button>
          <Button disabled={loading} onClick={() => setShowAll(false)} sx={{ backgroundColor: showAll ? null : Colors.BACKGROUND }}>
            {t('unread')}
          </Button>
        </Box>
        <Button disabled={loading} endIcon={<DoneAllIcon />} onClick={() => markAllNotificationsAsRead()}>
          {t('markAllAsRead')}
        </Button>
      </Box>
      <Box sx={{ my: 1 }}>
        {filteredNotifications && filteredNotifications?.length > 0 ? (
          filteredNotifications.map((item) => <NotificationListItem key={item.ID} item={item} />)
        ) : (
          <Box p={10} textAlign='center'>
            <Typography>{t('noUnreadMessages')}</Typography>
          </Box>
        )}
      </Box>
    </>
  );
};
