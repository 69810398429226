import { AxiosInstance } from 'src/services/axios/interceptors';
import { BaseAPI } from 'src/services/api/baseApi';
import { NotificationOptions } from 'src/services/api/options/notificationOptions';
import { NotificationRequest } from 'src/services/api/request/notificationRequest';
import { NotificationResponse } from 'src/services/api/response/notificationResponse';

export class NotificationAPI extends BaseAPI<NotificationResponse, NotificationOptions, NotificationRequest> {
  constructor() {
    super('Notification');
  }

  public SetNotificationReadStatus = (id: number) => AxiosInstance.post(`${this.controllerPath}/${id}/read`);

  public SetAllNotificationReadStatus = () => AxiosInstance.post(`${this.controllerPath}/read/all`);
}
