import { Typography, Box } from '@mui/material';
import ModalTemplate from 'src/shared/templates/modalTemplate';
import dayjs from 'dayjs';
import { NotificationResponse } from 'src/services/api/response/notificationResponse';
import { Colors } from 'src/shared/components/styles/colors';

type InformationModalProps = {
  notice: NotificationResponse | undefined;
  modalOpen: boolean;
  setModalOpen: (arg: boolean) => void;
};

export const InformationModal = (props: InformationModalProps) => {
  const { notice, modalOpen, setModalOpen } = props;

  return (
    <ModalTemplate
      isOpen={modalOpen}
      onClose={() => setModalOpen(false)}
      isLoading={false}
      title={`${notice?.title}`}
      subtitle={
        <Typography variant='subtitle1' sx={{ color: Colors.GRAY_DARK, fontSize: '14px' }}>
          {dayjs(notice?.date).format('D MMMM YYYY')}
        </Typography>
      }
      modalWidth='40%'
      content={
        <>
          {notice && (
            <>
              <Box sx={{ my: 2 }}>
                <Typography>{notice?.message}</Typography>
              </Box>
            </>
          )}
        </>
      }
    />
  );
};
