import { Avatar } from '@mui/material';
import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { getAbsenceTypeID } from 'src/modules/calendar/components/helper';
import { AttendenceDTO } from 'src/services/api/models/attendanceDto';
import { RootStateType } from 'src/services/redux/reducers';
import { Colors } from 'src/shared/components/styles/colors';

type AvatarWithBorderMeProps = {
  size: number;
  userToShow: AttendenceDTO;
};

export const AvatarWithBorderMe = (props: AvatarWithBorderMeProps) => {
  const { size, userToShow } = props;
  const { user } = useSelector((state: RootStateType) => state.oidc);

  const getBorderColor = useCallback(() => {
    if (userToShow.onDuty) {
      return Colors.GREEN;
    }
    if (userToShow.currentAbsence) {
      if (getAbsenceTypeID(userToShow.currentAbsence) === 1) {
        return Colors.RED;
      }
      if (getAbsenceTypeID(userToShow.currentAbsence) === 2) {
        return Colors.YELLOW;
      }
    }
    return 'transparent';
  }, [userToShow.currentAbsence, userToShow.onDuty]);

  const borderColor = getBorderColor();

  return (
    <Avatar
      src={`${userToShow.userImage}?access_token=${user?.access_token}`}
      sx={{
        width: size,
        height: size,
        borderRadius: '50%',
        border: `${size < 21 ? '1px' : '3px'} solid ${borderColor}`,
      }}
    />
  );
};
