import { TextField } from '@mui/material';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { useTranslation } from 'react-i18next';

type ConfirmDialogModalProps = {
  open: boolean;
  dialogText: string;
  dialogTitle?: string;
  handleClose: () => void;
  confirmButtonClick: () => void;
  onCommentChange?: (arg: any | undefined) => void;
  confirmTextButton?: string;
  cancelTextButton?: string;
  comment?: string;
  showComment?: boolean;
};
const ConfirmDialogModal = (props: ConfirmDialogModalProps) => {
  const {
    open,
    dialogTitle,
    dialogText,
    handleClose,
    confirmTextButton,
    cancelTextButton,
    confirmButtonClick,
    comment,
    showComment,
    onCommentChange,
  } = props;
  const { t } = useTranslation();
  return (
    <div>
      <Dialog open={open} onClose={handleClose} aria-labelledby='responsive-dialog-title'>
        <DialogTitle id='responsive-dialog-title'>{dialogTitle ?? t('common:confirm')}</DialogTitle>
        <DialogContent>
          <DialogContentText sx={{ color: 'text.primary' }}>{dialogText}</DialogContentText>
          {showComment && onCommentChange && (
            <TextField
              label={`${t('calendar:content.comment')}...`}
              value={comment}
              onChange={(arg) => onCommentChange(arg)}
              type='outlined'
              multiline
              minRows={3}
              maxRows={5}
              fullWidth
              margin='dense'
            />
          )}
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleClose}>
            {cancelTextButton ?? t('common:cancel')}
          </Button>
          <Button onClick={confirmButtonClick} autoFocus>
            {confirmTextButton ?? t('common:ok')}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

ConfirmDialogModal.defaultProps = {
  cancelTextButton: undefined,
  confirmTextButton: undefined,
  onCommentChange: undefined,
  comment: undefined,
  showComment: false,
  dialogTitle: undefined,
};

export default ConfirmDialogModal;
