/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { DragEvent, useState, useCallback, useEffect } from 'react';
import { Box, Button, CardMedia, Grid, IconButton, List, ListItem, Paper, Tooltip, Typography } from '@mui/material';
import { AddRounded, Close, InsertDriveFileOutlined } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';
import { RootStateType } from 'src/services/redux/reducers';
import getId from 'src/services/helpers/idGenerator';
import ConsoleHelper from 'src/services/helpers/consoleHelper';
import { AllValidTypes } from 'src/services/helpers/validTypes';
import { INewsDocument } from 'src/services/api/response/newsResponse';
import ConfirmDialogModal from '../atoms/confirmDialog';
import { Colors } from '../styles/colors';

type FileDropZoneProps = {
  onFilesChange: (files: File[]) => void;
  onPressDeleteInitialFile?: (file: INewsDocument) => void;
  initialFiles?: INewsDocument[] | undefined;
};

export interface FileExtended extends File {
  originalFilename?: string | undefined;
  id?: string | number | undefined;
  mimeType?: string | undefined;
}
const FileDropZone = (props: FileDropZoneProps) => {
  const { onFilesChange, initialFiles, onPressDeleteInitialFile } = props;

  const { t } = useTranslation('news');
  const [inputId] = useState(() => getId('fileInput-'));

  const { user } = useSelector((state: RootStateType) => state.oidc);

  const [validFiles, setValidFiles] = useState<FileExtended[]>([]);
  const [validCurrentFiles, setValidCurrentFiles] = useState<INewsDocument[] | undefined>(initialFiles);
  const [itemTobeDeleted, setItemTobeDeleted] = useState<INewsDocument | FileExtended | undefined>();
  const [showDeleteDialog, setShowDeleteDialog] = useState<boolean>(false);
  const [invalidFiles, setInvalidFiles] = useState<FileExtended[]>([]); // if we want to show a file list with invalid files..

  // Sätter state i parent för ändrade filer
  useEffect(() => {
    setValidCurrentFiles(initialFiles);
  }, [initialFiles]);

  // Sätter state i parent för ändrade filer
  useEffect(() => {
    // ConsoleHelper.log('change', validFiles);
    onFilesChange(validFiles);
  }, [validFiles]);

  // Validerar fil
  const validateFile = (newFile: FileExtended) => {
    if (AllValidTypes().indexOf(newFile.type) === -1) {
      return false;
    }
    return true;
  };

  // Validerar & sätter satet för filer
  const validateFiles = (files: FileList) => {
    for (let i = 0; i < files.length; i += 1) {
      const fileToAdd = files[i];
      if (validateFile(files[i])) {
        // add to an array so we can display the name of file
        setValidFiles((prevArray) => [...prevArray, fileToAdd]);
      } else {
        setInvalidFiles((prevArray) => [...prevArray, fileToAdd]);
        toast.warn(`${t('InvalidFile')}: ${fileToAdd.name}`);
      }
    }
  };

  // Metoder för drag & drop
  const fileDrop = (e: DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    const { files } = e.dataTransfer;
    validateFiles(files);
  };
  const dragOver = (e: DragEvent<HTMLDivElement>) => {
    e.preventDefault();
  };
  const dragEnter = (e: DragEvent<HTMLDivElement>) => {
    e.preventDefault();
  };
  const dragLeave = (e: DragEvent<HTMLDivElement>) => {
    e.preventDefault();
  };

  // On change i input elemtn
  const changeHandler = async (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    ConsoleHelper.log('changehandler');
    const { files } = e.target;
    if (files) validateFiles(files);
    e.target.value = ''; // Hack to reset input element (if you select the same file  after removing it wont work otherwise)
  };

  // Onclick radera fil
  const showDeleteModal = (file: FileExtended | INewsDocument) => {
    setItemTobeDeleted(file);
    setShowDeleteDialog(true);
  };

  // Raderar fil i lokal array
  const removeFile = useCallback(
    (name: string) => {
      const validFileIndex = validFiles.findIndex((e) => e.name === name);
      validFiles.splice(validFileIndex, 1);
      setValidFiles([...validFiles]);
    },
    [validFiles],
  );
  // Radera fil i initial array
  const removeInitialFile = useCallback(
    (id: number | undefined) => {
      if (validCurrentFiles) {
        const validFileIndex = validCurrentFiles.findIndex((e) => e.ID === id);
        validCurrentFiles.splice(validFileIndex, 1);
        setValidCurrentFiles([...validCurrentFiles]);
      }
    },
    [validCurrentFiles],
  );

  // Tar bort ny/tillagd fil efter confirm i modal
  const deleteNewFile = async () => {
    ConsoleHelper.log('item to remove from initialfiler eller nya filer', itemTobeDeleted);

    if (itemTobeDeleted && itemTobeDeleted instanceof File) {
      removeFile(itemTobeDeleted.name);
    } else if (itemTobeDeleted) {
      onPressDeleteInitialFile?.(itemTobeDeleted);
      removeInitialFile(itemTobeDeleted.ID);
    }
    setShowDeleteDialog(false);
  };

  // Är det bild eller dokument
  const showImage = (file: FileExtended) => {
    if (file.mimeType && file.mimeType.split('/')[0] === 'image') return true;
    if (file.type?.split('/')[0] === 'image') return true;
    return false;
  };
  const showCurrentDocuments = (file: INewsDocument) => {
    // TODO: Hur avgöra om filen är en bild från URL och då visa vbilden istälelt för ikon?
    return false;
  };

  const renderInitialList = useCallback(() => {
    return (
      validCurrentFiles && (
        <>
          <List dense sx={{ maxHeight: 200, overflowY: 'auto' }}>
            {validCurrentFiles?.map((file, idx) => {
              return (
                <ListItem
                  key={`${file.Name}${idx.toString()}`}
                  sx={{ px: 0, pr: '0 !important' }}
                  secondaryAction={
                    <IconButton size='small' sx={{ mr: -2 }} onClick={() => showDeleteModal(file)}>
                      <Tooltip title={t('common:delete')}>
                        <Close />
                      </Tooltip>
                    </IconButton>
                  }
                  divider
                >
                  {showCurrentDocuments(file) ? (
                    <CardMedia
                      sx={{
                        width: 50,
                        height: 50,
                      }}
                      image={`${file?.URL}?access_token=${user?.access_token}`}
                      onError={() => ConsoleHelper.log('error')}
                      title={file.Name}
                    />
                  ) : (
                    <InsertDriveFileOutlined sx={{ fontSize: 50 }} />
                  )}

                  <Typography component='span' variant='caption' sx={{ overflow: 'hidden', textOverflow: 'ellipsis', pl: 1 }}>
                    {file.Name}
                  </Typography>
                </ListItem>
              );
            })}
          </List>
        </>
      )
    );
  }, [user?.access_token, validCurrentFiles, t]);

  const renderList = useCallback(() => {
    return (
      <List dense sx={{ maxHeight: 200, overflowY: 'auto' }}>
        {validFiles.map((file, idx) => {
          return (
            <ListItem
              key={`${file.name}${idx.toString()}`}
              sx={{ px: 0, pr: '0 !important' }}
              secondaryAction={
                <IconButton size='small' sx={{ mr: -2 }} onClick={() => showDeleteModal(file)}>
                  <Tooltip title={t('common:delete')}>
                    <Close />
                  </Tooltip>
                </IconButton>
              }
              divider
            >
              {showImage(file) ? (
                <CardMedia
                  sx={{
                    width: 50,
                    height: 50,
                  }}
                  image={window.URL.createObjectURL(file)}
                  onError={() => ConsoleHelper.log('error')}
                  title={file.name}
                />
              ) : (
                <InsertDriveFileOutlined sx={{ fontSize: 50 }} />
              )}

              <Typography component='span' variant='caption' sx={{ overflow: 'hidden', textOverflow: 'ellipsis', pl: 1 }}>
                {file.name}
              </Typography>
            </ListItem>
          );
        })}
      </List>
    );
  }, [validFiles, t]);

  return (
    <>
      <Paper
        sx={{
          p: 1,
          width: '100%',
          border: '1px solid #CCCCCC',
        }}
        elevation={0}
        onDragOver={dragOver}
        onDragEnter={dragEnter}
        onDragLeave={dragLeave}
        onDrop={fileDrop}
      >
        <Grid
          container
          sx={{
            minHeight: 200,
            display: 'flex',
            flexDirection: { xs: 'column', md: 'row' },
            justifyContent: 'normal',
          }}
        >
          <Grid
            item
            xs={12}
            md={4}
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <label htmlFor={inputId}>
              <input
                style={{ display: 'none' }}
                id={inputId}
                name={inputId}
                type='file'
                multiple
                accept={AllValidTypes().toString()}
                onChange={changeHandler}
              />
              <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                <Button
                  variant='outlined'
                  component='span'
                  sx={{
                    width: 100,
                    height: 100,
                    color: Colors.GRAY_MEDIUM,
                    border: '2px dashed #CCCCCC',
                    borderRadius: 1,
                    '&:hover': {
                      color: Colors.ACCENT_DARK,
                      border: '2px dashed #CCCCCC',
                    },
                  }}
                >
                  <Tooltip title={t('common:addFile')}>
                    <AddRounded fontSize='large' />
                  </Tooltip>
                </Button>
              </Box>
            </label>
          </Grid>
          <Grid
            item
            xs={12}
            md={8}
            sx={{
              borderLeft: { xs: 'none', md: '1px solid #eee' },
              pl: { xs: 0, md: 2 },
            }}
          >
            <Typography variant='subtitle2' gutterBottom sx={{ wordBreak: 'break-word' }}>
              {t('files')} {`(${validFiles.length})`}
            </Typography>
            {validCurrentFiles && renderInitialList()}
            {renderList()}
          </Grid>
        </Grid>
        <ConfirmDialogModal
          open={showDeleteDialog}
          dialogTitle={t('common:confirm')}
          dialogText={t('common:confirmDelete')}
          handleClose={() => setShowDeleteDialog(false)}
          confirmButtonClick={() => deleteNewFile()}
          confirmTextButton={t('common:ok')}
          cancelTextButton={t('common:cancel')}
        />
      </Paper>
    </>
  );
};

FileDropZone.defaultProps = {
  initialFiles: undefined,
  onPressDeleteInitialFile: undefined,
};

export default React.memo(FileDropZone);
