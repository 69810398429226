import { PriorityHigh } from '@mui/icons-material';
import { keyframes } from '@emotion/react';
import { Colors } from '../styles/colors';

export const PrioritySymbol = () => {
  const pulse = keyframes`
    0% { transform: scale(1, 1) }
    50% { transform: scale(0.5, 0.5) }
    100% { transform: scale(1, 1) }
  `;

  return (
    <PriorityHigh
      sx={{
        color: Colors.ACCENT_LIGHT,
        animation: `${pulse} 1s linear infinite`,
      }}
    />
  );
};
