import { RelativeDTO } from 'src/services/api/models';
import { AxiosInstance, AxiosInstanceFormData } from 'src/services/axios/interceptors';
import { BaseAPI } from './baseApi';
import { EmployeeOptions } from './options/employeeOptions';
import { EmployeeRequest } from './request/employeeRequest';
import { EmployeeResponse } from './response/employeeResponse';

export class EmployeeAPI extends BaseAPI<EmployeeResponse, EmployeeOptions, EmployeeRequest> {
  constructor() {
    super('Employee');
  }

  // USER DATA
  public GetLoggedInUser = () => AxiosInstance.get(`${this.controllerPath}/me`);

  // RELATIVES
  public AddRelative = (guid: string, req: RelativeDTO) => AxiosInstance.post(`${this.controllerPath}/${guid}/relative`, req);

  public RemoveRelative = (guid: string, relativeGuid: string) =>
    AxiosInstance.delete(`${this.controllerPath}/${guid}/relative?relativeGuid=${relativeGuid}`);

  public EditRelative = (guid: string, req: RelativeDTO) => AxiosInstance.put(`${this.controllerPath}/${guid}/relative`, req);

  // MY PROGRESS
  // 1. Listan med kategorier
  public GetMyProgress = (GUID: string) => AxiosInstance.get(`${this.controllerPath}/${GUID}/progress`);

  // 2. Listan för vald kategori
  public GetMyProgressByCategory = (GUID: string, categoryGUID: string) =>
    AxiosInstance.get(`${this.controllerPath}/${GUID}/progress/${categoryGUID}`);

  // 3. Item från vald kategorilista
  public GetMyProgressByID = (GUID: string, categoryGUID: string, itemGUID: string) =>
    AxiosInstance.get(`${this.controllerPath}/${GUID}/progress/${categoryGUID}/item/${itemGUID}`);

  public CreateFormDataImage = (req: FormData) =>
    AxiosInstanceFormData.post(`${this.controllerPath}/uploadMediaImage`, req, {
      transformRequest: (data) => {
        return data;
      },
    });
}
