import { useCallback, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
// MUI
import {
  AppBar,
  Container,
  IconButton,
  Toolbar,
  Typography,
  Box,
  useMediaQuery,
  Badge,
  Menu,
  MenuItem,
  Avatar,
  ListItemIcon,
  ListItemText,
  Divider,
  Button,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import {
  Notifications,
  AccountCircle,
  Logout,
  Error,
  DateRange,
  Star,
  Groups,
  WorkOutline,
  SettingsOutlined,
  RestaurantMenu,
  ArticleOutlined,
} from '@mui/icons-material/';
// COMPONENTS
import EasyStaffLogo from 'src/assets/images/easystaff_logo-header.png';
import { NavigationMenu } from 'src/shared/components/navigationMenu';
import { Colors } from 'src/shared/components/styles/colors';
import { SwitchLanguageButton } from 'src/shared/components/switchLanguageButton';
// SERVICES
import { RoutesEnum } from 'src/shared/enums/routes.enum';
import userManager from 'src/services/identity/userManager';
import { useGlobals } from 'src/services/contexts/appContext';
import { RootStateType } from 'src/services/redux/reducers';
import { useTranslation } from 'react-i18next';
import { NotificationList } from 'src/modules/notifications/components/notificationList';
import { useInterval } from 'src/services/helpers/useInterval';
import ChatNotificationBadge from 'src/modules/chat/components/chatNotificationBadge';
import { Routes, usePrefetchRoutes } from 'src/services/helpers/prefetch';
import { useFetchInfinityChatrooms, useFetchPinnedChatrooms } from 'src/modules/chat/hooks/queries/useFetchChatList';
import { UserTypeEnum } from 'src/shared/enums/userType.enum';
import { headerMenuItems } from 'src/shared/components/helper/navigationMenuItems';
import { AvatarWithBorderMe } from 'src/modules/attendance/components/avatarWithBorderMe';

const TopNavigation = () => {
  const { t } = useTranslation();
  const theme = useTheme();
  const isTablet = useMediaQuery(theme.breakpoints.down('md'));
  // Globals
  const { loggedInUser, setShowChatModal, showChatModal, checkInStatusUser } = useGlobals();
  const { user } = useSelector((state: RootStateType) => state.oidc);
  const { unreadNotifications } = useGlobals();
  // Locals
  const [anchorProfileElement, setAnchorProfileElement] = useState<null | HTMLElement>(null);
  const [anchorNoticeElement, setAnchorNoticeElement] = useState<null | HTMLElement>(null);
  const openProfile = Boolean(anchorProfileElement);
  const openNotification = Boolean(anchorNoticeElement);

  const pinnedChatrooms = useFetchPinnedChatrooms({
    SortCol: 'LastActivityAt',
    SortDir: 'desc',
    Filter: '',
  });
  const chatlist = useFetchInfinityChatrooms({
    SortCol: 'LastActivityAt',
    SortDir: 'desc',
    Filter: '',
  });

  const handleProfileMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorProfileElement(event.currentTarget);
  };

  const handleNotificationsOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorNoticeElement(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorProfileElement(null);
    setAnchorNoticeElement(null);
  };

  return (
    <Container sx={{ m: 0, p: 0 }}>
      <AppBar position='fixed' elevation={0} sx={{ backgroundColor: Colors.GRAY_FAIR }}>
        <Toolbar
          sx={{
            display: 'flex',
            backgroundColor: 'white',
            color: 'text.primary',
            justifyContent: 'space-between',
          }}
        >
          <Box sx={{ display: 'flex', alignItems: 'center', flex: 0.3 }}>
            <Typography
              color='inherit'
              component={Link}
              to={RoutesEnum.Start}
              sx={{
                display: 'flex',
                '& a': { textDecoration: 'none', color: 'primary' },
              }}
            >
              <img
                src={EasyStaffLogo}
                srcSet={EasyStaffLogo}
                style={{
                  height: 40,
                  objectFit: 'contain',
                }}
                alt='logo-company'
                loading='lazy'
              />
            </Typography>
          </Box>
          {/* Menyval */}
          <Box
            style={{
              display: isTablet ? 'none' : 'flex',
              flex: 1,
              justifyContent: 'center',
            }}
          >
            <NavigationMenu menuItems={headerMenuItems} listItemDirection='column' listDirection='row' justifyContent='center' padding={0} />
          </Box>

          {/* Knappar i TopNavigation */}
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'flex-end',
              flex: 0.3,
            }}
          >
            <Box
              style={{
                display: isTablet ? 'none' : 'block',
              }}
            >
              <SwitchLanguageButton />
            </Box>

            {/* Chat */}

            <Button color='primary' aria-label='add' sx={{ color: Colors.BLACK }} onClick={() => setShowChatModal(!showChatModal)}>
              <ChatNotificationBadge />
            </Button>
            <IconButton
              id='notification-menu'
              aria-label={t('common:notifications')}
              aria-controls={openNotification ? 'notification-menu' : undefined}
              aria-haspopup='true'
              aria-expanded={openNotification ? 'true' : undefined}
              onClick={handleNotificationsOpen}
              sx={{
                mr: { lg: 2, xs: 0.3 },
                opacity: 1,
                color: Colors.BLACK,
              }}
            >
              <Badge badgeContent={unreadNotifications?.length} color='warning' max={99} overlap='circular'>
                <Notifications aria-label={t('common:notifications')} sx={{ fontSize: 26 }} />
              </Badge>
            </IconButton>

            <IconButton
              id='profile-menu'
              aria-controls={openProfile ? 'profile-menu' : undefined}
              aria-haspopup='true'
              aria-expanded={openProfile ? 'true' : undefined}
              onClick={handleProfileMenuOpen}
            >
              {checkInStatusUser && checkInStatusUser?.userImage ? (
                <AvatarWithBorderMe size={36} userToShow={checkInStatusUser} />
              ) : (
                <AccountCircle
                  sx={{
                    color: Colors.ACCENT_DARK,
                    fontSize: 36,
                  }}
                />
              )}
            </IconButton>
            <Menu id='profile-menu' anchorEl={anchorProfileElement} open={openProfile} onClose={handleClose}>
              {isTablet ? (
                <Box>
                  <MenuItem component={Link} to={RoutesEnum.Start} onClick={handleClose}>
                    <ListItemIcon aria-label={t('common:screens.news')}>
                      <Error sx={{ color: Colors.ACCENT_DARK }} />
                    </ListItemIcon>
                    <ListItemText>{t('common:screens.news')}</ListItemText>
                  </MenuItem>
                  <MenuItem component={Link} to={RoutesEnum.Calendar} onClick={handleClose}>
                    <ListItemIcon aria-label={t('common:screens.calendar')}>
                      <DateRange sx={{ color: Colors.ACCENT_DARK }} />
                    </ListItemIcon>
                    <ListItemText>{t('common:screens.calendar')}</ListItemText>
                  </MenuItem>
                  <MenuItem component={Link} to={RoutesEnum.Progress} onClick={handleClose}>
                    <ListItemIcon aria-label={t('common:screens.development')}>
                      <Star sx={{ color: Colors.ACCENT_DARK }} />
                    </ListItemIcon>
                    <ListItemText>{t('common:screens.development')}</ListItemText>
                  </MenuItem>
                  <MenuItem component={Link} to={RoutesEnum.Employee} onClick={handleClose}>
                    <ListItemIcon aria-label={t('common:screens.employees')}>
                      <Groups sx={{ color: Colors.ACCENT_DARK }} />
                    </ListItemIcon>
                    <ListItemText>{t('common:screens.employees')}</ListItemText>
                  </MenuItem>
                  <MenuItem component={Link} to={RoutesEnum.Form} onClick={handleClose}>
                    <ListItemIcon aria-label={t('common:screens.forms')}>
                      <ArticleOutlined sx={{ color: Colors.ACCENT_DARK }} />
                    </ListItemIcon>
                    <ListItemText>{t('common:screens.forms')}</ListItemText>
                  </MenuItem>
                  <Divider />
                </Box>
              ) : (
                ''
              )}
              <MenuItem component={Link} to={RoutesEnum.Profile} onClick={handleClose}>
                <ListItemIcon aria-label={t('common:profile')}>
                  <AccountCircle sx={{ color: Colors.ACCENT_DARK }} />
                </ListItemIcon>
                <ListItemText>{t('profile')}</ListItemText>
              </MenuItem>
              <MenuItem component={Link} to={RoutesEnum.Career} onClick={handleClose}>
                <ListItemIcon aria-label={t('common:screens.career')}>
                  <WorkOutline sx={{ color: Colors.ACCENT_DARK }} />
                </ListItemIcon>
                <ListItemText>{t('common:screens.career')}</ListItemText>
              </MenuItem>
              <MenuItem component={Link} to={RoutesEnum.LunchOrder} onClick={handleClose}>
                <ListItemIcon aria-label={t('lunch')}>
                  <RestaurantMenu sx={{ color: Colors.ACCENT_DARK }} />
                </ListItemIcon>
                <ListItemText>{t('lunch')}</ListItemText>
              </MenuItem>
              {loggedInUser?.UserType === UserTypeEnum.Admin && (
                <MenuItem component={Link} to={RoutesEnum.Admin} onClick={handleClose}>
                  <ListItemIcon aria-label={t('Admin')}>
                    <SettingsOutlined sx={{ color: Colors.ACCENT_DARK }} />
                  </ListItemIcon>
                  <ListItemText>{t('Admin')}</ListItemText>
                </MenuItem>
              )}

              {/* <Divider /> */}
              <MenuItem onClick={() => userManager.signoutRedirect()}>
                <ListItemIcon aria-label={t('common:logout')}>
                  <Logout sx={{ color: Colors.ACCENT_DARK }} />
                </ListItemIcon>
                <ListItemText>{t('common:logout')}</ListItemText>
              </MenuItem>
              {isTablet && (
                <MenuItem
                  sx={{
                    justifyContent: 'center',
                  }}
                >
                  <ListItemIcon>
                    <SwitchLanguageButton />
                  </ListItemIcon>
                </MenuItem>
              )}
            </Menu>

            <Menu
              id='notification-menu'
              anchorEl={anchorNoticeElement}
              open={openNotification}
              onClose={handleClose}
              PaperProps={{
                sx: {
                  width: { xs: '80%', md: '50%', lg: '30%' },
                  height: '100vh',
                },
              }}
            >
              <NotificationList />
            </Menu>
          </Box>
        </Toolbar>
      </AppBar>
    </Container>
  );
};
export default TopNavigation;
