import { Box } from '@mui/material';
import React from 'react';
import EasyStaffLogo from 'src/assets/images/easystaff_logo-4x.png';
import { Colors } from '../styles/colors';

const AppLoading = () => {
  return (
    <Box
      sx={{
        width: '100vw',
        height: '100vh',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: Colors.BACKGROUND,
      }}
    >
      <img
        src={EasyStaffLogo}
        srcSet={EasyStaffLogo}
        style={{
          height: 70,
          objectFit: 'contain',
          animation: 'pulse 2s linear infinite',
        }}
        alt='Loading...'
        loading='lazy'
      />
      <style>{`
          @keyframes pulse {
            0% { transform: scale(1, 1) }
            50% { transform: scale(1.15, 1.15) }
            100% { transform: scale(1, 1) }
          }
      `}</style>
    </Box>
  );
};

export default AppLoading;
