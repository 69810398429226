import { Box, Typography } from '@mui/material';
import { Participant } from 'src/services/api/response/participant';

type ParticipantsModalProps = {
  participants: Participant[];
};

const ParticipantsModal = (props: ParticipantsModalProps) => {
  const { participants } = props;
  return (
    <>
      {participants.length &&
        participants.map((user) => (
          <Box
            key={user.UserID}
            sx={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              alignItems: 'center',
              m: 2,
            }}
          >
            <Typography variant='body1'>{user.FullName}</Typography>
            {user.NumberOfInvitedPeople !== '0' && <Typography variant='h6' sx={{ ml: 0.5 }}>{`+ ${user.NumberOfInvitedPeople}`}</Typography>}
          </Box>
        ))}
    </>
  );
};

export default ParticipantsModal;
