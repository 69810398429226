import { AttachFile } from '@mui/icons-material';
import { Button, ListItem, Typography } from '@mui/material';
import { useSelector } from 'react-redux';
import { getDocumentName } from 'src/services/helpers/validTypes';
import { RootStateType } from 'src/services/redux/reducers';
import { Colors } from 'src/shared/components/styles/colors';

type ViewFileProps = {
  doc: string;
};

const ViewFile = (props: ViewFileProps) => {
  const { doc } = props;
  const { user } = useSelector((state: RootStateType) => state.oidc);

  const onFileClicked = (url: string) => {
    window.open(`${url}?access_token=${user?.access_token}`);
  };

  return (
    <Button
      variant='contained'
      sx={{
        p: '0 !important',
        backgroundColor: Colors.GRAY_LIGHT,
        boxShadow: 'none',
        mb: 1,
        color: Colors.GRAY_DARK,

        ':hover': {
          backgroundColor: Colors.GRAY_MEDIUM,
          boxShadow: 'none',
          color: Colors.GRAY_LIGHT,
        },
      }}
      onClick={() => onFileClicked(doc)}
    >
      <ListItem
        sx={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          maxWidth: '100vW',

          px: 1,
        }}
      >
        <AttachFile sx={{ fontSize: 28, mr: 1 }} />
        <Typography variant='body1' sx={{ fontSize: 12 }}>
          {getDocumentName(doc)}
        </Typography>
      </ListItem>
    </Button>
  );
};

export default ViewFile;
