import { lazy, Suspense, useEffect, useMemo } from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import { Navigate, createBrowserRouter, RouterProvider } from 'react-router-dom';
import { RoutesEnum } from 'src/shared/enums/routes.enum';
import AppRoutesLayout from 'src/shared/routes/helper/appRoutesLayout';
import RequireAuth from 'src/shared/routes/helper/requireAuth';
import { RequireAdminStatus } from 'src/shared/routes/helper/requireAdminStatus';
import SilentRenewPage from 'src/modules/auth/pages/silentRenewPage';
import CallbackPage from 'src/modules/auth/pages/callbackPage';
import { createTheme, ThemeProvider } from '@mui/material';
import { svSE } from '@mui/material/locale';
import SilentSignin from './modules/auth/components/silentSignin';
import { Colors, updateColors } from './shared/components/styles/colors';
import { useGlobals } from './services/contexts/appContext';
import ConsoleHelper from './services/helpers/consoleHelper';

const HomePage = lazy(() => import('src/modules/home/pages/homePage'));
const CalendarPage = lazy(() => import('src/modules/calendar/pages/calendarPage'));
const AbsenceReportPagePage = lazy(() => import('src/modules/admin/pages/absenceReportPage'));
const ScheduleReportPage = lazy(() => import('src/modules/admin/pages/scheduleReportPage'));
const UnAnsweredAbsencePage = lazy(() => import('src/modules/admin/pages/unansweredAbsencePage'));
const CareerPage = lazy(() => import('src/modules/career/pages/careerPage'));
const EmployeePage = lazy(() => import('src/modules/employee/pages/employeePage'));
const ProgressPage = lazy(() => import('src/modules/progress/pages/progressPage'));
const ProfilePage = lazy(() => import('src/modules/profile/pages/profilePage'));
const LunchAdminPage = lazy(() => import('src/modules/admin-lunch/pages/lunchAdminPage'));
const LunchOrderPage = lazy(() => import('src/modules/lunch/pages/lunchOrderPage'));

const AdminPage = lazy(() => import('src/modules/admin/pages/adminPage'));

const LoginPage = lazy(() => import('src/modules/auth/pages/loginPage'));

const FormPage = lazy(() => import('src/modules/form/pages/formPage'));

const router = createBrowserRouter([
  {
    path: RoutesEnum.CallbackPage,
    element: <CallbackPage />,
  },
  {
    path: RoutesEnum.SilentRenewPage,
    element: <SilentRenewPage />,
  },
  {
    path: RoutesEnum.Start,
    element: <AppRoutesLayout />,
    children: [
      {
        index: true,
        element: (
          <RequireAuth>
            <HomePage />
          </RequireAuth>
        ),
      },
      {
        path: RoutesEnum.Calendar,
        element: (
          <RequireAuth>
            <CalendarPage />
          </RequireAuth>
        ),
      },
      {
        path: RoutesEnum.Career,
        element: (
          <RequireAuth>
            <CareerPage />
          </RequireAuth>
        ),
      },
      {
        path: RoutesEnum.Form,
        element: (
          <RequireAuth>
            <FormPage />
          </RequireAuth>
        ),
      },
      {
        path: RoutesEnum.Employee,
        element: (
          <RequireAuth>
            <EmployeePage />
          </RequireAuth>
        ),
      },
      {
        path: RoutesEnum.Progress,
        element: (
          <RequireAuth>
            <ProgressPage />
          </RequireAuth>
        ),
      },
      {
        path: RoutesEnum.Profile,
        element: (
          <RequireAuth>
            <ProfilePage />
          </RequireAuth>
        ),
      },
      {
        path: RoutesEnum.LunchOrder,
        element: (
          <RequireAuth>
            <LunchOrderPage />
          </RequireAuth>
        ),
      },
      {
        path: RoutesEnum.Admin,
        element: (
          <RequireAdminStatus>
            <AdminPage />
          </RequireAdminStatus>
        ),
      },
      {
        path: RoutesEnum.AdminLunch,
        element: (
          <RequireAdminStatus>
            <LunchAdminPage />
          </RequireAdminStatus>
        ),
      },
      {
        path: RoutesEnum.AbsenceReport,
        element: (
          <RequireAuth>
            <AbsenceReportPagePage />
          </RequireAuth>
        ),
      },
      {
        path: RoutesEnum.UnAnsweredAbsence,
        element: (
          <RequireAuth>
            <UnAnsweredAbsencePage />
          </RequireAuth>
        ),
      },

      {
        path: RoutesEnum.ScheduleReport,
        element: (
          <RequireAuth>
            <ScheduleReportPage />
          </RequireAuth>
        ),
      },
      {
        path: RoutesEnum.Login,
        element: (
          <Suspense fallback={<div />}>
            <LoginPage />
          </Suspense>
        ),
      },
    ],
  },
  {
    path: '*',
    element: <Navigate to={RoutesEnum.Start} replace />,
  },
]);

const App = () => {
  const { loggedInUser } = useGlobals();

  useEffect(() => {
    // Hämta färger från API:et
    const apiColors = {
      PRIMARY: '#005F97',
      SECONDARY: '#2B7BB9',
      ACCENT_DARK: '#005F97',
      ACCENT_LIGHT: '#EEC400',
    };

    if (loggedInUser?.GUID === 'b1c0c1c9-2ad1-4d71-9ff3-46ab7dc2ca67') {
      ConsoleHelper.log('!!! DU ÄR SOFIE - BYT TEMA !!!');
      updateColors(apiColors); // Tema för Sofie Andersson
    } else {
      ConsoleHelper.log('!!! DU ÄR INTE SOFIE - DEFAULT TEMA !!!');
      updateColors(null); // Återställ till standardfärger
    }
  }, [loggedInUser]);

  const theme = useMemo(
    () =>
      createTheme(
        {
          breakpoints: {
            values: {
              xs: 0,
              sm: 650,
              md: 900,
              lg: 1200,
              xl: 1536,
            },
          },
          palette: {
            text: {
              primary: Colors.BLACK,
              secondary: Colors.PRIMARY,
            },
            primary: {
              main: Colors.ACCENT_DARK,
            },
            secondary: {
              main: Colors.SECONDARY,
            },
            error: {
              main: Colors.RED,
            },
            warning: {
              main: Colors.WARNING,
            },
            info: {
              main: Colors.YELLOW,
            },
            success: {
              main: Colors.GREEN,
            },
            background: {
              paper: Colors.WHITE,
              default: Colors.BACKGROUND,
            },
          },
          typography: {
            h1: {
              fontFamily: 'Menco',
              fontSize: 24,
              fontWeight: 500,
              color: Colors.ACCENT_DARK,
              textTransform: 'uppercase',
            },
            h2: {
              fontFamily: 'Menco',
              fontSize: 23,
              fontWeight: 500,
              color: Colors.BLACK,
            },
            h3: {
              fontFamily: 'Menco',
              fontSize: 21,
              fontWeight: 500,
              color: Colors.BLACK,
            },
            h4: {
              fontFamily: 'Menco',
              fontSize: 19,
              fontWeight: 500,
              color: Colors.BLACK,
            },
            h5: {
              fontFamily: 'Menco',
              fontSize: 17,
              fontWeight: 500,
              color: Colors.BLACK,
            },
            h6: {
              fontFamily: 'Menco',
              fontSize: 15,
              fontWeight: 600,
              color: Colors.BLACK,
            },
            subtitle1: {
              fontSize: 13,
              color: Colors.BLACK,
              fontWeight: 500,
            },
            subtitle2: {
              fontSize: 14,
              color: Colors.BLACK,
              fontWeight: 700,
            },
            body1: {
              fontSize: 14,
              lineHeight: 1.5,
            },
            body2: {
              fontSize: 14,
              lineHeight: 1.5,
            },
            button: {
              fontFamily: 'Menco',
              textTransform: 'uppercase',
            },
            fontFamily: [
              'acumin-pro',
              'Open Sans',
              '-apple-system',
              'BlinkMacSystemFont',
              '"Segoe UI"',
              'Roboto',
              '"Helvetica Neue"',
              'Arial',
              'sans-serif',
              '"Apple Color Emoji"',
              '"Segoe UI Emoji"',
              '"Segoe UI Symbol"',
            ].join(','),
          },
          components: {
            MuiCssBaseline: {
              styleOverrides: {
                body: {
                  overflowY: 'scroll',
                  '&::-webkit-scrollbar, & *::-webkit-scrollbar': {
                    backgroundColor: 'transparent',
                    width: '8px',
                  },
                  '&::-webkit-scrollbar-thumb, & *::-webkit-scrollbar-thumb': {
                    borderRadius: 8,
                    backgroundColor: 'rgba(0, 0 ,0 , 0.3)',
                    minHeight: 24,
                    width: '8px',
                  },
                  '&::-webkit-scrollbar-thumb:focus, & *::-webkit-scrollbar-thumb:focus': {
                    backgroundColor: 'rgba(0, 0 ,0 , 0.5)',
                  },
                  '&::-webkit-scrollbar-thumb:active, & *::-webkit-scrollbar-thumb:active': {
                    backgroundColor: 'rgba(0, 0 ,0 , 0.5)',
                  },
                  '&::-webkit-scrollbar-thumb:hover, & *::-webkit-scrollbar-thumb:hover': {
                    backgroundColor: 'rgba(0, 0 ,0 , 0.5)',
                  },
                  '&::-webkit-scrollbar-corner, & *::-webkit-scrollbar-corner': {
                    backgroundColor: 'transparent',
                  },
                  '& .MuiBackdrop-root': {
                    backgroundColor: 'rgba(0, 0, 0, 0.8)',
                  },
                  '& .MuiCard-root': {
                    boxShadow: '0px 5px 5px -3px rgb(0 0 0 / 10%), 0px 8px 10px 1px rgb(0 0 0 / 4%), 0px 3px 14px 2px rgb(0 0 0 / 2%)',
                    borderRadius: 8,
                  },
                  '& .MuiToolbar-root': {
                    boxShadow: '0px 5px 5px -3px rgb(0 0 0 / 10%), 0px 8px 10px 1px rgb(0 0 0 / 4%), 0px 3px 14px 2px rgb(0 0 0 / 2%)',
                  },
                },
              },
            },
          },
        },
        svSE,
      ),
    [loggedInUser],
  );
  return (
    <>
      <ThemeProvider theme={theme}>
        <SilentSignin />
        <CssBaseline />
        <RouterProvider router={router} />
      </ThemeProvider>
    </>
  );
};
export default App;
