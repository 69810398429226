/* eslint-disable no-param-reassign */
import axios from 'axios';
import qs from 'qs';
// import ConsoleHelper from '../helpers/consoleHelper';
import { store } from '../redux/store';

declare module 'axios' {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface AxiosResponse<T = any> extends Promise<T> {}
}

// AXIOS SETUP INSTANCE
export const getBaseURL = async () => {
  /*  const parts = window.location.hostname.split('.');
  let lastIndex = -2;
  const last = parts[parts.length - 1];
  const isLocalhost = last === 'localhost';
  if (isLocalhost) {
    lastIndex = -1;
  }
  const subdomain = parts.slice(0, lastIndex).join('.');

  if (!subdomain) return `https://apptech.easystaff.se/api/v1`;

  return `https://${subdomain}.easystaff.se/api/v1`; */

  return `https://${'apptech'}.easystaff.se/api/v1`;
};

// CREATE AXIOS INSTANCE
export const AxiosInstance = axios.create({
  timeout: 20000,
  headers: {
    'Content-Type': 'application/json',
  },
});

// AXIOS PARAMS
AxiosInstance.defaults.paramsSerializer = (params: unknown) => {
  const formatted = qs.stringify(params);
  return formatted;
};

// AXIOS REQUEST
AxiosInstance.interceptors.request.use(
  async (config: any) => {
    // ConsoleHelper.log('intercetor request: ', config);
    const { oidc } = store.getState();
    // config.headers['X-Language'] = getAppLanguage() ?? 'EN';
    // config.headers['X-Version'] = '2.0.0|10146|2022-01-09';
    // config.baseURL = await getBaseURL();
    if (oidc && oidc?.user?.access_token) {
      config.headers.Authorization = `Bearer ${oidc?.user?.access_token}`;
    } else {
      config.headers.Authorization = `Bearer ${oidc?.user?.access_token}`;
    }
    return config;
  },
  (error: any) => Promise.reject(error),
);

// AXIOS RESPONSE
// // Destruct data from response - returnerar res.data så att vyer kan kolla res.Results direkt
// const handleResponse = ({ data, request }: AxiosResponse) => {
//   return data;
// };
// AxiosInstance.interceptors.response.use(handleResponse);

AxiosInstance.interceptors.response.use(
  (res: any) => {
    // ConsoleHelper.log('axios-interceptors response::', res);
    return res.data;
  },
  async (err: any) => {
    const originalRequest = err.config;
    if (err?.response?.status === 401 && !originalRequest.retry) {
      originalRequest.retry = true;
    } else if (err?.response?.status === 401) {
      window.location.href = '/';
    }
    return Promise.reject(err.response);
  },
);

export default {
  get: AxiosInstance.get,
  post: AxiosInstance.post,
  put: AxiosInstance.put,
  delete: AxiosInstance.delete,
  patch: AxiosInstance.patch,
};

// Interceptors form-data
// Axios instance form-data i interceptors.ts
export const AxiosInstanceFormData = axios.create({
  timeout: 20000,
  headers: {
    'Content-Type': 'multipart/form-data',
  },
});

// Request
AxiosInstanceFormData.interceptors.request.use(
  async (config: any) => {
    // ConsoleHelper.log('intercetor request form-data: ', config);
    const { oidc } = store.getState();
    if (oidc && oidc?.user?.access_token) {
      config.headers.Authorization = `Bearer ${oidc?.user?.access_token}`;
    } else {
      config.headers.Authorization = `Bearer ${oidc?.user?.access_token}`;
    }
    return config;
  },
  (error) => Promise.reject(error),
);

// response
AxiosInstanceFormData.interceptors.response.use(
  (res: any) => {
    // ConsoleHelper.log('axios-interceptors response form-data::', res);
    return res.data;
  },
  async (err: any) => {
    const originalRequest = err.config;
    if (err?.response?.status === 401 && !originalRequest.retry) {
      originalRequest.retry = true;
    } else if (err?.response?.status === 401) {
      window.location.href = '/';
    }
    return Promise.reject(err.response);
  },
);
