export enum NewsCategoryEnum {
  WeeklyLetter = 'Veckobrev',
  MonthlyLetter = 'Månadsbrev',
  Activities = 'Aktiviteter',
  WorkingEnvironment = 'Arbetsmiljö',
  ManagerLetter = 'Chefsbrev',
  All = 'Alla',
  StaffDocument = 'Personaldokument',
  General = 'Allmänt',
}
