import dayjs from 'dayjs';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { ToastOptions, toast } from 'react-toastify';
import { EmployeeAPI } from 'src/services/api/employeeApi';
import { AttendenceDTO } from 'src/services/api/models/attendanceDto';
import { AxiosInstance, AxiosInstanceFormData } from 'src/services/axios/interceptors';
import { useGlobals } from 'src/services/contexts/appContext';
import ConsoleHelper from 'src/services/helpers/consoleHelper';
import { useIsMounted } from 'src/services/helpers/useIsMounted';
import userManager from 'src/services/identity/userManager';
import { RootStateType } from 'src/services/redux/reducers';
import AppLoading from 'src/shared/components/atoms/appLoading';

const SilentSignin = () => {
  const { t, i18n } = useTranslation();
  const isMounted = useIsMounted();
  const { setLoggedInUser, setCheckInStatusUser } = useGlobals();
  const user = useSelector((state: RootStateType) => state.oidc.user);
  const { domain } = useSelector((state: RootStateType) => state.appState);
  const [loading, setLoading] = useState(false);

  const toastIdErrorFetchUser = 'toast-error-fetch-user'; // To prevent duplicate toasts.

  const trySilentSigning = useCallback(async () => {
    setLoading(true);
    try {
      const silentResult = await userManager.signinSilent();
      if (isMounted()) {
        ConsoleHelper.log('Silent login success', silentResult);
      }
    } catch (error) {
      if (isMounted()) {
        ConsoleHelper.warn('Silent login failed');
      }
    } finally {
      if (isMounted()) {
        setLoading(false);
      }
    }
  }, [isMounted]);

  useEffect(() => {
    trySilentSigning();
  }, [trySilentSigning]);

  useEffect(() => {
    const fetchUser = async () => {
      const employeeAPI = new EmployeeAPI();
      const result = await employeeAPI.GetLoggedInUser();
      setLoggedInUser(result);
    };
    if (user && !user.expired && AxiosInstance.defaults.baseURL && AxiosInstance.defaults.baseURL.length > 0) {
      fetchUser().catch((error) => {
        const options: ToastOptions = {
          autoClose: 10000,
          position: 'top-center',
          toastId: toastIdErrorFetchUser,
          onClose: () => userManager.signoutRedirect(),
          pauseOnHover: false,
          pauseOnFocusLoss: false,
        };
        toast.error(t('alerts.404GetLoggedInUser'), options);
      });
    }
  }, [user, setLoggedInUser, t]);

  useEffect(() => {
    const fetchUser = async () => {
      const employeeAPI = new EmployeeAPI();
      const result = await employeeAPI.GetLoggedInUser();
      const checkInUser: AttendenceDTO = {
        userGuid: result.GUID,
        userImage: result.Image,
        firstName: result.Firstname,
        currentAttendence: result.CurrentAttendance,
        currentAbsence: result.CurrentAbsence,
        onDuty: result.OnDuty,
      };
      setCheckInStatusUser(checkInUser);
    };
    if (user && !user.expired && AxiosInstance.defaults.baseURL && AxiosInstance.defaults.baseURL.length > 0) {
      fetchUser();
    }
  }, [setCheckInStatusUser, user]);

  useEffect(() => {
    AxiosInstance.defaults.baseURL = `https://${domain}.easystaff.se/api/v1`;
    AxiosInstanceFormData.defaults.baseURL = `https://${domain}.easystaff.se/api/v1`;
  }, [domain]);

  return loading ? <AppLoading /> : null;
};

export default SilentSignin;
