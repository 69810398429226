import { useQuery } from '@tanstack/react-query';
import { notificationKeys } from 'src/modules/notifications/hooks/notificationKeys';
import { NotificationAPI } from 'src/services/api/notificationAPI';
import { NotificationOptions } from 'src/services/api/options/notificationOptions';
import { NotificationResponse } from 'src/services/api/response/notificationResponse';

const notificationApi = new NotificationAPI();

const getNotifications = async (options: NotificationOptions) => {
  const res = await notificationApi.GetAll(options);
  const castedResult = res as unknown as NotificationResponse[];
  return castedResult;
};

export const useFetchNotifications = (options: NotificationOptions, loggedInUser: any) => {
  return useQuery({
    queryKey: notificationKeys.list(options),
    queryFn: () => getNotifications(options),
    refetchInterval: 60 * 1000, // Refetching notification list every 60 seconds
    enabled: !!loggedInUser, // Transform loggedInUser into a boolean. If loggedInUser is undefined or null, it becomes false; otherwise, true.
  });
};
