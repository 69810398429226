import { Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, TextField } from '@mui/material';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { AbsenceResponse } from 'src/services/api/response/absenceResponse';
import { useApproveAbsence, useDisapproveAbsence } from 'src/modules/calendar/hooks/mutations/useMutationAbsence';
import { useState } from 'react';
import ConfirmDialogModal from 'src/shared/components/atoms/confirmDialog';

type HandleApplicationButtonsProps = {
  absence: AbsenceResponse;
  closeModal?: () => void;
  fetchAbsenceReports?: () => void;
};

export const HandleApplicationButtons = (props: HandleApplicationButtonsProps) => {
  const { absence, closeModal, fetchAbsenceReports } = props;
  const { t } = useTranslation('calendar');
  const [commentText, setComment] = useState<string>('');
  const [showApproveConfirmDialog, setShowApproveConfirmDialog] = useState<boolean>(false);
  const [showDisapproveDialog, setShowDisapproveDialog] = useState<boolean>(false);
  // useMutations
  const approveAbsence = useApproveAbsence(absence.GUID);
  const disapproveAbsence = useDisapproveAbsence();

  // Godkänna ansökan
  const approveAbsenceHandler = async () => {
    if (absence) {
      approveAbsence.mutate(absence.GUID, {
        onSuccess: () => {
          setShowApproveConfirmDialog(false);
          if (fetchAbsenceReports) fetchAbsenceReports();
          if (closeModal) closeModal();
        },
        onError: () => {
          toast.error('Kunde inte hantera ansökan, försök igen.');
        },
      });
    }
  };

  // Neka ansökan
  const disapproveAbsenceHandler = async () => {
    if (absence) {
      disapproveAbsence.mutate(
        {
          guid: absence?.GUID,
          req: {
            ManagerComment: commentText ?? '',
          },
        },
        {
          onSuccess: () => {
            if (fetchAbsenceReports) fetchAbsenceReports();
            if (closeModal) closeModal();
          },
          onError: () => {
            toast.error('Kunde inte hantera ansökan, försök igen.');
          },
        },
      );
    }
  };

  return (
    <Box sx={{ my: 2 }}>
      {/* Om ansökan ej är godkänd eller avslagen och datum ej har passerat */}
      {!absence.Approved && !absence.AnswerDate && dayjs().format('YYYY-MM-DD') <= dayjs(absence.To).format('YYYY-MM-DD') && (
        <>
          <Button variant='contained' disabled={approveAbsence.isLoading} onClick={() => approveAbsenceHandler()} color='success'>
            {t('approveLeave')}
          </Button>

          <Button variant='contained' disabled={disapproveAbsence.isLoading} onClick={() => setShowDisapproveDialog(true)} sx={{ ml: 1 }}>
            {t('denyleave')}
          </Button>
        </>
      )}
      {/* Om ansökan ej är besvarad och datum för ledigheten har passerat */}
      {!absence.AnswerDate && dayjs().format('YYYY-MM-DD') > dayjs(absence.To).format('YYYY-MM-DD') && (
        <>
          <Button variant='contained' disabled>
            {t('applicationDatePassed')}
          </Button>
        </>
      )}
      {/* Om ansökan redan är godkänd */}
      {absence.Approved && (
        <>
          <Button variant='contained' disabled>
            {t('leaveIsApproved')}
          </Button>
        </>
      )}
      {/* Om ansökan är avslagen */}
      {!absence.Approved && absence.AnswerDate && (
        <Button variant='contained' disabled>
          {t('leaveWasDenied')} {dayjs(absence.AnswerDate).format('YYYY-MM-DD')}
        </Button>
      )}

      {/* Godkänna frånvaro */}
      <ConfirmDialogModal
        open={showApproveConfirmDialog}
        dialogTitle={t('common:confirm')}
        dialogText={t('common:admin.doYouWantToApprove')}
        handleClose={() => setShowApproveConfirmDialog(false)}
        confirmButtonClick={() => approveAbsenceHandler()}
        confirmTextButton={t('common:ok')}
        cancelTextButton={t('common:cancel')}
      />

      {/* Neka frånvaro */}
      <Dialog fullWidth open={showDisapproveDialog} onClose={() => setShowDisapproveDialog(false)} aria-labelledby='disapprove-dialog'>
        <DialogTitle id='disapprove-dialog-title'>{t('calendar:denyLeave')}</DialogTitle>
        <DialogContent>
          <DialogContentText sx={{ color: 'text.primary' }}>{t('calendar:confirmDeniedAbsence')}</DialogContentText>
          <TextField
            label={`${t('calendar:reasonForDeniedAbsence')}...`}
            value={commentText}
            onChange={(e) => setComment(e.target.value)}
            type='outlined'
            multiline
            minRows={3}
            maxRows={5}
            fullWidth
            margin='dense'
          />
        </DialogContent>
        <DialogActions>
          <Button
            autoFocus
            onClick={() => {
              setShowDisapproveDialog(false);
            }}
          >
            {t('common:cancel')}
          </Button>
          <Button
            onClick={() => {
              setShowDisapproveDialog(false);
              disapproveAbsenceHandler();
            }}
            autoFocus
          >
            {t('common:yes')}
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

HandleApplicationButtons.defaultProps = {
  closeModal: undefined,
  fetchAbsenceReports: undefined,
};
