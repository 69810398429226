import { Box, Button, ButtonGroup, Dialog, DialogActions, DialogContent, DialogContentText, IconButton, Typography } from '@mui/material';
import { KeyboardArrowDown, KeyboardArrowUp } from '@mui/icons-material';
import dayjs from 'dayjs';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { NewsResponse } from 'src/services/api/response/newsResponse';
import ConfirmDialogModal from 'src/shared/components/atoms/confirmDialog';
import { Colors } from 'src/shared/components/styles/colors';
import { toast } from 'react-toastify';
import { useAddParticipant, useRemoveParticipant } from 'src/modules/home/hooks/mutations/useParticipation';

type ActivityButtonsProps = {
  newsItem: NewsResponse;
};

const ActivityButtons = (props: ActivityButtonsProps) => {
  const { newsItem } = props;
  const { t } = useTranslation(['news']);

  const [showConfirmDialog, setShowConfirmDialog] = useState<boolean>(false);
  const [showInviteMoreModal, setShowInviteMoreModal] = useState<boolean>(false);
  const [count, setCount] = useState<number>(0);

  const addParticipant = useAddParticipant();
  const removeParticipant = useRemoveParticipant();

  const cancelParticipation = async () => {
    removeParticipant.mutate(newsItem.GUID, {
      onSuccess: () => {
        toast.info(t('unsubscribe'));
        setShowConfirmDialog(false);
      },
    });
  };

  const willParticipate = async () => {
    if (newsItem.HasManyInvinted) {
      setShowInviteMoreModal(true);
    } else {
      addParticipant.mutate(
        { newsItemGuid: newsItem.GUID, nrOfUsers: '0' },
        {
          onSuccess: () => toast.success(t('userAlreadyRegistered')),
        },
      );
    }
  };

  const willParticipateInviteMore = async () => {
    addParticipant.mutate(
      {
        newsItemGuid: newsItem.GUID,
        nrOfUsers: count.toString(),
      },
      {
        onSuccess: () => {
          setShowInviteMoreModal(false);
          toast.success(t('userAlreadyRegistered'));
          setCount(0);
        },
      },
    );
  };

  const incrementCount = () => {
    setCount(count >= 10 ? count : count + 1);
  };

  const decrementCount = () => {
    setCount(count <= 0 ? 0 : count - 1);
  };

  if (newsItem.DeadlineDate && dayjs().format('YYYY-MM-DDTHH:mm') > dayjs(newsItem.DeadlineDate).format('YYYY-MM-DDT23:59')) {
    return newsItem.CurrentUserAlreadyParticipating ? (
      <>
        <Button
          variant='contained'
          disabled
          sx={{
            '&.Mui-disabled': {
              background: Colors.GREEN,
              color: Colors.BACKGROUND,
            },
            mr: 1,
          }}
        >
          {t('userAlreadyRegistered')}
        </Button>
      </>
    ) : (
      <Button variant='contained' disabled sx={{ bgcolor: Colors.GRAY_MEDIUM }}>
        {t('registrationDatePassed')}
      </Button>
    );
  }

  return newsItem.CurrentUserAlreadyParticipating ? (
    <>
      <ButtonGroup>
        <Button
          disabled
          variant='contained'
          sx={{
            color: Colors.BACKGROUND,
            mr: 1,
            '&.Mui-disabled': {
              background: Colors.GREEN,
              color: Colors.BACKGROUND,
            },
          }}
        >
          {t('userAlreadyRegistered')}
        </Button>
        <Button onClick={() => setShowConfirmDialog(true)} variant='contained' color='error'>
          {t('cancelParticipation')}
        </Button>
      </ButtonGroup>
      <ConfirmDialogModal
        open={showConfirmDialog}
        dialogTitle={t('common:confirm')}
        dialogText={t('cancelParticipation')}
        handleClose={() => setShowConfirmDialog(false)}
        confirmButtonClick={cancelParticipation}
        confirmTextButton={t('common:ok')}
        cancelTextButton={t('cancel')}
      />
    </>
  ) : (
    <>
      <Button disabled={addParticipant.isLoading || removeParticipant.isLoading} onClick={willParticipate} variant='contained'>
        {t('iwantToParticipate')}
      </Button>
      <Dialog open={showInviteMoreModal} onClose={() => setShowInviteMoreModal(false)}>
        <DialogContent>
          <DialogContentText>
            {t('enterNumberOfParticipants')} {t('inAdditionToYourself')}
          </DialogContentText>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <Typography variant='h2' paddingRight={3}>
              {count}
            </Typography>
            <ButtonGroup orientation='vertical' sx={{ bgcolor: Colors.GREEN, mt: 2 }}>
              <IconButton onClick={incrementCount}>
                <KeyboardArrowUp sx={{ color: Colors.WHITE }} />
              </IconButton>
              <IconButton onClick={decrementCount}>
                <KeyboardArrowDown sx={{ color: Colors.WHITE }} />
              </IconButton>
            </ButtonGroup>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setShowInviteMoreModal(false)}>{t('cancel')}</Button>
          <Button onClick={willParticipateInviteMore}>{t('registerparticipants')}</Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default ActivityButtons;
