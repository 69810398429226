import { FormControlLabel, Switch, Typography, Box } from '@mui/material';
import { Controller, useFormContext } from 'react-hook-form';

type ControlledSwitchProps = {
  label: JSX.Element;
  name: string;
  info: string;
  labelPlacement: any;
};

export const ControlledSwitch = (props: ControlledSwitchProps) => {
  const { label, name, info, labelPlacement } = props;
  const { control } = useFormContext();
  return (
    <Controller
      name={name}
      control={control}
      render={({ field }) => (
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'flex-start',
            alignItems: 'flex-start',
          }}
        >
          <FormControlLabel
            label={label}
            labelPlacement={labelPlacement}
            control={<Switch checked={field.value} onChange={(e) => field.onChange(e)} />}
            sx={{ ml: '0' }}
          />
          <Typography
            sx={{
              color: (theme) => theme.palette.grey[500],
              fontStyle: 'italic',
            }}
            variant='subtitle1'
          >
            {info}
          </Typography>
        </Box>
      )}
    />
  );
};
