import { useQuery } from '@tanstack/react-query';
import { DepartmentAPI } from 'src/services/api/departmentApi';
import { DepartmentResponse } from 'src/services/api/response/departmentResponse';
import { PagedResult } from '../api/helpers/pagedResult';

const getDepartments = async (includeChoiceAll: boolean) => {
  const departments = new DepartmentAPI();
  const res: PagedResult<DepartmentResponse> = await departments.GetDepartments();
  let departmentsList = res.Results;
  if (includeChoiceAll) {
    departmentsList = [{ GUID: '', Name: 'Alla', ID: 0 }, ...departmentsList];
  }
  return departmentsList;
};

export const useFetchDepartments = (includeChoiceAll: boolean) => {
  return useQuery({
    queryKey: ['departments', includeChoiceAll],
    queryFn: () => getDepartments(includeChoiceAll),
    staleTime: 60 * 4 * 1000,
  });
};
