import { useQuery, QueryClient } from '@tanstack/react-query';
import dayjs from 'dayjs';
import { calendarKeys } from 'src/modules/calendar/hooks/calendarKeys';
import { AbsenceAPI } from 'src/services/api/absenceApi';
import { AbsenceOptions } from 'src/services/api/options/absenceOptions';
import { AbsenceResponse } from 'src/services/api/response/absenceResponse';
import ConsoleHelper from 'src/services/helpers/consoleHelper';

const abesenceApi = new AbsenceAPI();
const queryClient = new QueryClient();

export const getAbsence = async (options: AbsenceOptions) => {
  const res = await abesenceApi.GetAll(options);
  return res.Results;
};

export const getAbsenceById = async (id: string) => {
  const res: AbsenceResponse = await abesenceApi.GetAbsenceById(id);
  return res;
};

export const getNextFetchDates = async (next: AbsenceOptions) => {
  const newOptions = next;

  // TODO: Denna duration måste räknas ut exakt rätt beroende på vilken view man valt i kalendern (Vecka, Månad, 60 dagar) Annars sätts fel query key och cachen blir oanvändbar
  const duration = (dayjs(newOptions.To).diff(dayjs(newOptions.From), 'days') + 1) * 2;
  ConsoleHelper.log('FETCH NEXT::', duration);

  newOptions.From = dayjs(newOptions.From).add(duration, 'days').format('YYYY-MM-DD');
  newOptions.To = dayjs(newOptions.To).add(duration, 'days').format('YYYY-MM-DD');
  await queryClient.prefetchQuery({
    queryKey: calendarKeys.list(newOptions),
    queryFn: () => getAbsence(newOptions),
  });
};

export const getPreviousFetchDates = async (prev: AbsenceOptions) => {
  const newOptions = prev;

  // TODO: Denna duration måste räknas ut exakt rätt beroende på vilken view man valt i kalendern (Vecka, Månad, 60 dagar) Annars sätts fel query key och cachen blir oanvändbar
  const duration = dayjs(newOptions.To).diff(dayjs(newOptions.From), 'days') + 1;
  ConsoleHelper.log('FETCH PREV::', duration);

  newOptions.From = dayjs(newOptions.From).subtract(duration, 'days').format('YYYY-MM-DD');
  newOptions.To = dayjs(newOptions.To).subtract(duration, 'days').format('YYYY-MM-DD');
  await queryClient.prefetchQuery({
    queryKey: calendarKeys.list(newOptions),
    queryFn: () => getAbsence(newOptions),
  });
};

export const useFetchAbsenceList = (options: AbsenceOptions) => {
  return useQuery({
    queryKey: calendarKeys.list(options),
    queryFn: () => getAbsence(options),
    staleTime: 10 * 1000, // TODO: eventuell justera?
  });
};

export const useFetchAbsenceById = (absenceId: string) => {
  return useQuery({
    queryKey: calendarKeys.byId(absenceId),
    queryFn: () => getAbsenceById(absenceId),
  });
};
