import { BaseAPI } from './baseApi';
import { ChatRoomResponse } from './response/chatResponse';
import { ChatRoomOptions } from './options/chatOptions';
import { ChatRoomRequest } from './request/chatRequest';
import { AxiosInstance } from '../axios/interceptors';

// TODO: Importera från src
export class ChatRoomAPI extends BaseAPI<ChatRoomResponse, ChatRoomOptions, ChatRoomRequest> {
  constructor() {
    super('ChatRoom');
  }

  public TogglePinRoom = (ID: number) => AxiosInstance.put(`${this.controllerPath}/${ID}/Pin`);
}
