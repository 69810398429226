import { ChatRoomMessageOptions, ChatRoomOptions } from 'src/services/api/options/chatOptions';

export const chatKeys = {
  all: ['chat'] as const,
  messages: () => [...chatKeys.all, 'message'] as const,
  employees: (id: number) => [...chatKeys.messages(), 'employees', id] as const,
  lists: () => [...chatKeys.all, 'list'] as const,
  list: (options?: ChatRoomOptions) => [...chatKeys.lists(), { ...options }] as const,
  pinnedList: (options?: ChatRoomOptions) => [...chatKeys.lists(), 'pinned', { ...options }] as const,
  infinityMessage: (options: ChatRoomMessageOptions) => [...chatKeys.messages(), 'infinity', { ...options }] as const,
  infinityList: (options?: ChatRoomOptions) => [...chatKeys.lists(), 'infinity', { ...options }] as const,
  byId: (id: number) => [...chatKeys.all, id] as const,
};
